// sass-lint:disable no-important
body.account-open{
	background-color: $gray-dark-30;
}

.marginThirdSection {
	margin-top: 37px;
}

.bat-header-gloit {
	background-color: $brand-black;
	font-size: 16px;

	header {
		height: $header-height-mobile;
		padding: 0 0 0 20px;
		position: relative;
		z-index: $header-z-index - 2;

		@include media-breakpoint-up(lg) {
			height: auto;
			margin: 0 auto;
			max-width: $max-width-xl;
			padding: 0 20px;
		}
	}

	.bat-header-logo{
		display: flex;
		flex: 0 0 94px;
		align-items: center;

		@include media-breakpoint-down(md) {
			flex: 0 0 auto;
		}
	}

	.bat-header-also-visit {
		margin-right: auto;

		.also-visit-label{
			span{
				padding: 0px 15px;
				font-size: 10px;
				font-family: $primary-font;
				color: $gray;
			}	
		}

		.also-visit-logo-vype{
			a{
				padding-top: 5px
			}	
		}
	}

	.bat-header-support,
	.bat-header-stores,
	.bat-header-cart,
	.bat-header-account,
	.bat-header-loyalty-info,
	.bat-header-notification-bell{
		height: auto;
		order: unset;
		padding: 15px;

		@include media-breakpoint-up(sm) {
			padding: 20px;
		}

		a,
		button {
			display: flex;
			flex-direction: column;

			&:hover {
				border: 0;
			}

			i {
				height: 28px;
				width: 28px;

				&:before {
					color: $white;
					font-size: $icon-size;
					transition: background-color 0.1s ease-in;
				}
			}

			span {
				color: $white;
				font-size: 10px;
				line-height: 10px;
			}

			/*@include media-breakpoint-up(lg) {
				&:hover {
					i {
						&:before {
							color: $white;
						}
					}

					span {
						color: $white;
						text-decoration: none;
					}
				}
			}*/
		}

		ul.no-auth-links {
			display: block;
		}
		ul.auth-links {
			display: none;
		}
	}

	.bat-header-support-link {
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;

		a {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			padding: 7px 16px;
			gap: 10px;
			border: 1px solid #FFFFFF;
			border-radius: 100px;

			&:hover {
				border: 1px solid #FFFFFF !important;
				background-color: $white;
				span {
					color: $brand-black;
				}
			}

			.bat-icon {
				@include media-breakpoint-up(md) {
					display: none;
				}
			}

			span {
				font-size: 12px;
				line-height: 16px;
			}
		}
	}

	.bat-header-loyalty-info {
		display: flex;

		@include media-breakpoint-down(md) {
			padding-right: 5px;
			max-width: 155px;
		}

		&--account-info {
			display: flex;
			align-items: center;
			justify-content: center;
			background: #3A3A3A;
			border-radius: 24px 0px 0px 24px;
		}

		.account-loyalty-tier {
			display: flex;
			align-items: center;
			
			@include media-breakpoint-down(md) {
				display: block;
			}
		}

		.account-name {
			margin-left: 7px;
			margin-right: 10px;
			background: #D5DDEC;
			color: #18207F;
			border-radius: 100%;
			width: 24px;
			height: 24px;
			font-weight: 700;
			font-size: 12px;
			line-height: 26px;
			text-align: center;

			@include media-breakpoint-down(md) {
				margin-right: 5px;
				width: 35%;
			}
		}

		.account-loyalty-title {
			font-weight: 700;
			font-size: 12px;
			height: 24px;
			line-height: 26px;
			text-align: center;
			letter-spacing: 1.2px;
			text-transform: uppercase;
			color: $white;
			margin-right: 10px;

			@include media-breakpoint-down(md) {
				margin-right: 5px;
				font-size: 10px;
				height: 13px;
				line-height: 13px;
				margin-bottom: 10px;
			}
		}

		.account-loyalty-circles {
			display: flex;
			justify-content: space-between;
			width: 32px;
			margin-right: 8px;

			.small-circle {
				width: 8px;
				height: 8px;
				border-radius: 100%;
				background: #6B6B6B;

				&.circle-orange {
					background: #CD4A04;
				}
			}
		}

		.button-loyalty {
			background: #6B6B6B;
			color: $white;
			border: 0;
			padding: 10px 13px;
    		border-radius: 0px 24px 24px 0px;
			
			.icon-arrow-loyalty {
				height: 0;
				width: 15px;
				display: flex;
				
				&::after {
					content: "\e910";
					font-family: "icomoon";
					font-size: 28px;
				}
			}
		}
	}

	.bat-header-account {
		&-link{
			a{
				text-decoration: none;
			}
		}

		&-menu {
			display: none;

			&.open{
				display: block;
				border: 0.5px solid $black;
				background-color: $white;
				left: 0%;
				margin: 0;
				padding: 5px 15px;
				position: absolute;
				transform: translate(-50%, 5%);
				width: 185px;
				z-index: 1000;
				overflow-x: hidden;
				overflow-y: auto;
	
				a {
					text-align: left;
					text-decoration: none;
					font-family: $primary-font;
					font-weight: 800;
					color: $black;
					padding: 0px;
	
					span{
						color: $black;
						border-bottom: 1px solid $gray;
						padding-bottom: 10px;
						padding-top: 10px;
					}
	
					/*&:hover {
						span {
							color: $white;
						}
					}*/
				}
	
				li {
					display: block;
				}

				li:last-child{
					a{
						span{
							border-bottom: none;
						}
					}
				}
			}
		}

		&.open {
			a {
				i {
					&:before {
						color: $white;
					}
				}
			}
		}
		&.authenticated {
			ul.auth-links {
				display: block;
			}
			ul.no-auth-links {
				display: none;
			}
			.bat-header-account-icon-label {
				display: none;
			}
			.bat-header-account-icon-label-auth {
				display: inline;
			}
		}

		@include media-breakpoint-down(md) {
			padding-left: 5px;
    		padding-right: 5px;
		}
	}

	.bat-header-cart {
		// sass-lint:disable class-name-format
		&.cartEmpty {
			button {
				i {
					&:before {
						.bat {
							&:not(bat-header-limited) & {
								content: "\e91a";
							}
						}
					}
				}
			}
		}

		&-label {
			font-size: 10px;
			font-weight: normal;
			letter-spacing: 0;
			line-height: 10px;
			margin: 0 auto;
			text-transform: capitalize;
		}

		&-count {
			align-items: center;
			background-color: red;
			border-radius: 25px;
			color: $white;
			display: flex;
			font-size: 10px;
			font-weight: 700;
			justify-content: center;
			line-height: 10px;
			margin: auto;
			opacity: 0;
			transform: rotate(0deg);
			transition: all 0.2s ease-in;

			&.loading {
				animation: spin 2s linear infinite;
				border-bottom: 2px solid $light-blue;
				border-top: 2px solid $light-blue;
				visibility: visible;

				&.small,
				&.medium &.large {
					span {
						opacity: 0;
					}
				}

				& ~ span {
					visibility: visible;
				}
			}

			@keyframes spin {
				0% {
					transform: rotate(0deg);
				}
				100% {
					transform: rotate(360deg);
				}
			}

			&.small,
			&.medium,
			&.large {
				bottom: 60%;
				height: 15px;
				left: 30%;
				margin: auto;
				position: absolute;
				right: 0;
				width: 15px;

				span {
					opacity: 1;
					transition: opacity 0.2s ease-in;
				}
			}

			&.active {
				opacity: 1;
			}
		}

		span {
			font-size: 10px;
			line-height: 10px;
		}

		@include media-breakpoint-down(md) {
			padding-left: 5px;
    		padding-right: 5px;
		}
	}

	.bat-header-timer{
		display: none;
		color: white;
		width:220px;
		align-content: center;
		margin-top: 1%;
		.bat-header-cart-timer{
			@include media-breakpoint-down(sm) {
				font-size: 12px;
			}
		}
		@include media-breakpoint-down(sm) {
			width: 165px;
    		padding-left: 7%;
			margin-top: 1%;
			.bat-header-cart-timer{
				width: 85%;
			}
		}
	}

	.bat-header-social-nav {
		ul {
			li {
				color: $white;
			}
		}
	}

	.bat-header-menu {
		background-color: $charcoal;
		height: 0;
		//transition: height .2s ease;

		@include media-breakpoint-up(lg) {
			height: auto;
			position: absolute;
			top: auto;
		}

		&.open {
			height: auto;
			overflow-y: auto;
			top: 70px !important;
			max-height: calc(100vh - #{$warning-height-mobile * 2});

			@include media-breakpoint-up(lg) {
				height: auto;
				overflow: hidden;
			}
		}
		&-button {
			padding: 15px;
			@include media-breakpoint-up(sm) {
				padding: 20px;
			}
		}

		.uk-search{
			border-bottom: 1px solid $gray;
			margin-right: 5px;
			position: relative;

			&-input {
				font-size: 10px;
				background-color: $brand-black;
				height: 35px;
				padding-left: 0;
			}
		}
	}

	.bat-header-desktop-nav {
		background-color: $charcoal;
		display: flex;
		flex: 0;

		padding: 20px 0;
		width: 100%;

		.bat-navigation {
			margin: 0 auto;
			// max-width: 500px;
			&-group-list-item--velo-mobile {
				@include media-breakpoint-up(lg) {
					display: none;
				}
				a {
					i {
						margin-left: 0;
						margin-right: 2em;
						transform: scale(2);
						transform-origin: left center;
						&:before {
							font-size: 1em;
						}
					}
				}
			}

			&-group-list{
				padding-left: 15px;
				padding-right: 40px
			}			
		}

		@include media-breakpoint-up(lg) {
			flex: 1 1 auto;
			justify-content: flex-start;
			padding: 0 20px;

			.bat-navigation {
				margin: 0 auto;
				max-width: $max-width-xl;
				width: 100%;
			}
		}
	}

	.bat-header-nav {
		display: none;
		@media (max-width: 1024px) {
			display: block;
		}
		flex: 0;
		padding: 20px 0;

		.bat-navigation {
			margin: 0 auto;
			max-width: 500px;
			&-group-list-item--velo-mobile {
				@include media-breakpoint-up(lg) {
					display: none;
				}
				a {
					i {
						margin-left: 0;
						margin-right: 2em;
						transform: scale(2);
						transform-origin: left center;
						&:before {
							font-size: 1em;
						}
					}
				}
			}
		}

		@include media-breakpoint-up(lg) {
			flex: 1 1 auto;
			justify-content: flex-start;
			padding: 0 20px;

			.bat-navigation {
				margin: 0 auto;
				max-width: 700px;
				width: 94%;
			}
		}
	}

	.bat-header-menu-button {
		color: $white;
		// padding: 20px 12px;
		order: 0;
		position: relative;

		button {
			border: 0;
			height: auto;
			width: auto;

			i {
				&:before {
					color: $white;
					font-size: $icon-size;
				}
			}
		}

		&.active {
			background-color: $brand-black;

			button {
				i {
					&:before {
						color: $white;
						font-size: 28px;
					}
				}
			}
		}

		&-open-label {
			color: $white;
			margin-top: 0;
		}

		&-close-label {
			margin-top: 0;
		}

		&-close-label,
		&-open-label {
			@include font-rem(11px, 11px);
		}

		@include media-breakpoint-down(md) {
			padding-left: 5px;
    		padding-right: 5px;
		}
	}

	.bat-header-fixed-spacer {
		height: auto;
	}

	.bat-header-content {
		background-color: $white;

		.bat-text {
			background-color: $white;
			bottom: 0;
			display: flex;
			flex-wrap: wrap;
			height: $warning-height-mobile;
			justify-content: flex-start;
			left: 0;
			margin: 0 auto;
			max-width: none;
			position: fixed;
			right: 0;
			top: auto;
			z-index: $header-z-index;

			@include media-breakpoint-up(md) {
				height: $warning-height;
			}

			p {
				margin: 0;

				&:nth-child(1) {
					align-items: center;
					background-color: $white;
					border: solid 6px $black;
					color: $black;
					display: flex;
					font-family: $google-lato;
					font-size: 16px;
					font-weight: 700;
					height: $warning-height-mobile;
					justify-content: center;
					line-height: 16px;
					padding: 0 4%;
					text-align: center;
					width: 100%;

					@include media-breakpoint-up(md) {
						font-size: 26px;
						height: $warning-height;
						line-height: 26px;
					}
				}

				&:nth-child(2),
				&:nth-child(3) {
					border-top: solid 1px $black;
					color: $black;
					font-family: $open-sans;
					font-size: 10px;
					height: $warning-footer-height;
					letter-spacing: 1px;
					line-height: 10px;
					padding: 8px 10px 0;
					text-align: left;
					width: 50%;

					@include media-breakpoint-up(xs) {
						font-size: 11px;
						line-height: 11px;
					}

					br {
						@include media-breakpoint-up(lg) {
							content: " ";
							display: none;
						}
					}
				}

				&:nth-child(3) {
					margin-left: auto;
					text-align: right;
				}
			}
		}
	}

	.second-section {
		background-color: $charcoal;

		.second-navbar {
			.uk {
				&-navbar {
					&-center {
						background-color: $charcoal;
					}

					&-container {
						background-color: $charcoal;
					}
				}
				&-dropdown {
					left: 0;
				}
				&-grid {
					margin-left: 0;
				}
				&-first-column {
					padding-left: 0;
				}
			}

			li {
				&:not(:last-child, :nth-child(5n)) {
					width: 80%;
				}

				&:last-child {
					width: 50%;
				}

				&:nth-child(5n) {
					width: 120%;
				}
			}

			a {
				padding: 0;

				&:hover {
					color: $orange;
				}

				&:active {
					color: $orange;
				}

				min-height: 60px;
				min-width: 100%;
				font-size: 12px;
				color: $white;
				font-weight: 800;
				@include media-breakpoint-up(md) {
					font-size: 14px;
				}
			}
		}

		.uk {
			&-container {
				max-width: 1400px;
			}

			&-dropdown {
				margin: 0;
				padding: 0;
			}
			&-navbar-container {
				a.uk-button {
					color: $white;
				}
			}
		}

		.description-text {
			max-width: 135px;

			&-caption {
				margin-top: 0;
				font-size: 13px;
				line-height: 18px;
				font-weight: 500;
			}

			&-title {
				margin-bottom: 10px;
				max-width: 50px;
				font-size: 18px;
				line-height: 20px;
				font-weight: 700;
			}
		}

		.left-section {
			padding: 40px 15px 15px 15px;
			@include media-breakpoint-up(md) {
				padding: 60px 30px 30px 30px;
			}
		}

		
		.container-slider {
			padding: 10px;
			background-color: $charcoal;

			.slider-menu {
				.container-button {
					border: 0.5px solid $white;
					line-height: 26px;
					min-width: 13px;
					border-radius: 13px;
					padding-top: 5px;
					padding-bottom: 5px;
					text-transform: unset;

					.button-text-size {
						font-size: 10px;
						font-weight: 800;
						line-height: 15px;
						text-align: center;
					}
				}
			}

			.uk-grid > * {
				&:not(:first-child) {
					padding-left: 5px;
				}
				@include media-breakpoint-up(md) {
					&:not(:first-child) {
						padding-left: 20px;
					}
				}
				@include media-breakpoint-up(md) {
					&:not(:first-child) {
						padding-left: 25px;
					}
				}
			}

			.slidenav-previous{
				background: linear-gradient(90deg,#3a3a3a,#3a3a3a,#3a3a3a, transparent);
			}
			.slidenav-next{
				background: linear-gradient(90deg, transparent,#3a3a3a,#3a3a3a,#3a3a3a);
			}
		}
	}

	.third-section{
		overflow: hidden;
		transition: height 0.3s ease-out;
		height: auto;
		width: 100%;
		position: absolute;
		.uk-container{
			max-width: 100%;
		}

		.uk-navbar-nav {
			gap: 0 !important;
		}
	}
	.bat-header-message-bar {
		background-color: $banner-background;
		color: $white;
		padding: 5px 20px;
		width: 100%;
		margin-bottom: 0;
		
		@include media-breakpoint-up(xl){
			&:first-child{
				justify-content: flex-end;
			}
	
			&:last-child{
				justify-content: flex-start;
			}
		}
		
		&-message {
			align-content: center;
			align-items: center;
			display: flex;
			justify-content: center;

			i {
				margin-right: 10px;
				margin-left: 30px;
			}

			span {
				p {
					@include font-rem(12px, 15px);
					margin-bottom: 0;
				}
			}
		}
	}
	.bat-header--velo-desktop {
		@include media-breakpoint-down(md) {
			display: none;
		}
	}
	[class*="flag-circle"] {
		svg {
			border-radius: 50%;
			transform: scale(0.8);
		}
	}

	a [class^="icon-"],
	a [class*=" icon-"] {
		display: inline;
	}

	&.mobile-header {
		background-color: $white;

		header {
			height: 75px;
			justify-content: space-between;

			.inverted-header & {
				flex-direction: row-reverse;
   				padding: 0 20px;
				align-items: center;

				.bat-header-right-section {
					font-weight: 700;
					font-size: 20px;
					line-height: 26px;
				}
			}

			.narrow-header & {
				justify-content: flex-start;
				gap: 20px;
				align-items: center;

				.bat-header-right-section {
					font-weight: 700;
					font-size: 20px;
					line-height: 26px;
				}
			}
		}

		.bat-header-loyalty-info {
			display: flex;
	
			@include media-breakpoint-down(md) {
				padding-right: 5px;
				max-width: unset;
			}
	
			&--account-info {
				background: #EFEFEF;
				border-radius: 24px;
				height: 48px;
			}
	
			.account-loyalty-tier {
				display: flex;
				align-items: center;
				
				@include media-breakpoint-down(md) {
					display: block;
				}
			}
	
			.account-name {
				display: flex;
				align-items: center;
				justify-content: center;
	
				@include media-breakpoint-down(md) {
					width: 32px;
    				height: 32px;
				}
			}
	
			.account-loyalty-title {
				color: #2A2C2C;
				width: max-content;
				margin-bottom: 0;

				@include media-breakpoint-down(md) {
					margin-right: 14px;
				}
			}
		}

		.bat-header-cart-link {
			background: #EFEFEF;
			border-radius: 100%;
			width: 48px;
			height: 48px;
			display: flex;
			justify-content: center;
			align-items: center;

			.icon-bag {
				height: 32px;
				width: 30px;

				&::before {
					content: url('/content/dam/glo-it/images/mobileapp/bag.svg');
					font-size: 26px;
					color: #2a2c2c;
				}
			}
		}
		
		.bat-header-notification-bell-link {
			background: #EFEFEF;
			border-radius: 100%;
			width: 48px;
			height: 48px;
			display: flex;
			justify-content: center;
			align-items: center;

			.notificationIcon {
				height: 32px;
				width: 16px !important;

				&::before {
					content: url('/content/dam/glo-it/images/mobileapp/notificationIcon.svg');
					font-size: 26px;
					color: #2a2c2c;
				}
			}
		}
		.bat-header-notification-bell-count{
			align-items: center;
			background: linear-gradient(267.63deg, #cd4a04 .65%, #ed7d30 100%);
			border-radius: 25px;
			color: $white;
			display: flex;
			font-size: 10px;
			font-weight: 700;
			justify-content: center;
			line-height: 10px;
			margin: auto;
			opacity: 0;
			transform: rotate(0deg);
			transition: all 0.2s ease-in;
			
			&.small{
				bottom: 60%;
				height: 15px;
				left: 60%;
				margin: auto;
				position: absolute;
				right: 0;
				width: 15px;

				span {
					opacity: 1;
					transition: opacity 0.2s ease-in;
				}
			}
			&.active{
				opacity: 1;
			}
		}
	}
}

.bat-header-loader {
	display: none;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.4);
	z-index: 999999999999;
	touch-action: auto;
	pointer-events: all;
	position: fixed;

	&.active {
		display: block;
		&:after {
			-webkit-animation: spin 2s linear infinite; /* Safari */
			animation: spin 2s linear infinite;
			border: 10px solid $light-gray;
			border-radius: 50%;
			border-top: 10px solid $purple;
			bottom: 0;
			content: "";
			height: 60px;
			left: 0;
			margin: auto;
			position: absolute;
			right: 0;
			top: 0;
			width: 60px;
			z-index: 2;
		}
	}
}

bat-header-glors.safari-fix .bat-header-menu.open {
	max-height: calc(100vh - #{$warning-height-mobile * 2.5});
}

.icon-support {
	height: 15px !important;
}

.gloit-notification-container{
	display: flex;
    flex-direction: column;
    margin: 5.5%;
}

.gloit-notification-content{
    margin-bottom: 5%;
    height: 140px;
    position: relative;
    &-header{
		margin-bottom: 1%;
    }
    &-text{
      	margin-bottom:5%;
      	display: -webkit-box;
		-webkit-line-clamp: 3; 
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis; 
    }
    &-data{
	  color: #AAAAAA;
	  position: absolute;
	  bottom: 0;
	  right: 0;
	  margin-bottom: 5%;
    }
    &::after{
    	  content: "";
          display: block;
          width: 100%; 
          height: 1px; 
          background-color: #dedede; 
          position: absolute;
          bottom: 0; 
          left: 0;
    }
    .notification-dot::after {
          content: ""; 
		  background-color: #cd4a04; 
		  width: 9px; 
		  height: 9px; 
		  border-radius: 50%; 
		  display: inline-block; 
		  margin-left: 10px; 
		  vertical-align: middle;
      }
      .notification-dot-hide::after{
      	display: none!important;
      }
}

.unread{
	color:#CD4A04;
}


