.aem-GridColumn{
	&.news-card{
		padding: 70px 50px;
        background-color: $concrete;

		@include media-breakpoint-down(sm){
			padding: 0px;
		}
    }

    .title-section{
        @include media-breakpoint-down(sm){
            display: block;
			text-align: center;
            padding-top: 30px;
		}
        
    }

    @include media-breakpoint-down(xl){
        .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--tablet--6 {
            width: 100%;
        }
    }

    .news-section-title{
        @include font-rem(22px, 28px);
        font-weight: 800;

        @include media-breakpoint-down(sm){
            margin-bottom: 10px;
		}
    }

    .news-section-link{
        text-decoration: none;
        color: $charcoal;
    
        @include font-rem(14px, 28px);
        font-weight: 800;

        i{
            margin-top: 2px;
            margin-left: 10px;
        }
    }
}
.bat-cardnews-gloit{
    &--news {
		margin-top: 8px;

		@include media-breakpoint-down(xs) {
			margin-bottom: 0;
			margin-top: 0;
			padding: 40px 10px 30px;
		}

		&-img {
			height: 100%;
			max-height: 240px;
			overflow: hidden;

			a {
				display: block;
			}
		}

        &-title{
            @include font-rem(20px, 28px);
            font-weight: 800;
        }

        &-content{
            @include font-rem(14px, 20px);
        }

		&-text {
			margin: 15px 0;

			@include media-breakpoint-down(xs) {
				margin: 8px 0 5px;
			}

			h3 {
				font-size: 20px;
				line-height: 150%;
				padding-top: 15px;

				@include media-breakpoint-down(xs) {
					padding-top: 4px;
				}
			}
			p {
				font-size: 15px;
				line-height: 150%;
				margin-top: 5px;

				@include media-breakpoint-down(xs) {
					color: $dark-gray;
				}
			}
		}
		&-ctalist {
			.bat-cta-style {
				font-size: 15px;
				line-height: 150%;
				margin-top: 5px;
                text-transform: uppercase;
                @include font-rem(15px, 28px);
                font-weight: 800;
                color: $charcoal;

				@include media-breakpoint-down(sm) {
					margin-top: 0;
				}
				
			}
			
			.button-news{
				@include media-breakpoint-up(sm){
					&:hover {
						transform: translateX(50px);
					}
					transition: all .5s ease-out;
				}
				
			}

            a{
                text-decoration: none;
                color: $charcoal;
            
                @include font-rem(14px, 28px);
                font-weight: 800;
        
                i{
                    margin-top: 2px;
                    margin-left: 10px;
                }
            }
            a:hover{
                text-decoration: none;
                color: $charcoal;

                span{
                    text-decoration: none;
                    color: $charcoal;
                }
            }

            span{
                font-weight: 800;
            }

            @include media-breakpoint-down(sm) {
                padding-top: 10px;
            }
		}
	}
}

.artandmore-news{
    .aem-GridColumn{
        background-color: transparent;

        &.aem-GridColumn--default--4{
            margin-top: 20px;
            padding-right: 5px;
            padding-left: 5px;

            @include media-breakpoint-down(sm){
                margin-top: 5px;
            }

            &:last-of-type{ 
                .bat-cardnews-gloit--news{
                    border-bottom: none;
                }
            }
        }
        
    }

    .artandmore-news-title{
        text-align: center;
        @include font-rem(30px, 34px);
        margin-top: 40px;
        margin-bottom: 30px;

        @include media-breakpoint-down(sm){
            @include font-rem(24px, 28px);
            margin-bottom: 0px;
            margin-left: 20px;
            margin-right: 20px;
            text-align: left;
        }
    }

    .bat-cardnews-gloit--news{
        @include media-breakpoint-down(sm){
            margin-left: 20px;
            margin-right: 20px;
            padding: 20px 0px;
            border-bottom: 2px solid #DEDEDE;
        }   

        .bat-cardnews-gloit--news-date{
            @include font-rem(14px, 20px);
        }

        .bat-cardnews-gloit--news-title{
            margin-top: 0px;
        }
    }
}

.gloitmclaren-section {
    .bat-cardnews-gloit--news{
        @include media-breakpoint-down(sm){
            padding-top: 5px;
        }
    }
}

#news{
    .bat-cardnews-gloit{
        &--news {
            margin-top: 8px;
    
            @include media-breakpoint-down(xs) {
                margin-bottom: 0;
                margin-top: 0;
                padding: 20px 10px 30px;
                border-bottom: 2px solid #DEDEDE;
            }
        }
    }
}

.news-alternative {
    @include media-breakpoint-down(sm) {
        .bat-carousel {
            width: 100%;
            margin-left: 0;
            margin-right: 0;
        }

        .bat-cardnews-gloit {
            &--news {
                display: flex;
                flex-direction: row-reverse;
                height: 200px;
                padding: 0;
                align-items: center;

                &-img {
                    display: flex;
                    max-height: unset;
                    min-width: 45%;
                    
                    img {
                        object-fit: cover;
                        height: 100%;
                    }
                }

                &-main {
                    padding: 10px;
                    padding-left: 15px;
                }

                &-title {
                    padding: 0;
                }

                &-content {
                    h3 {
                        @include font-rem(16px, 21px);
                    }

                    p {
                        @include font-rem(14px, 20px);
                    }
                }

                &-ctalist {
                    padding-bottom: 10px;
                }
            }
        }
    }
}