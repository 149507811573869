.bat-insidersclub-gloit{
    width: 87%;
    margin: auto;

    @include media-breakpoint-down(lg){
        width: auto;
        margin: auto 20px;
    }

    .comefunziona-link{
        font-size: 14px;
        font-weight: 700;
        line-height: 17px;
        letter-spacing: 0.7px;
        align-items: center;

        img{
            margin-left: 10px;
        }

        @include media-breakpoint-down(md) {
            margin-bottom: 20px;
        }
    }

    &-userLevel{
        background: linear-gradient(45deg, #5482C2 0, #353174 100%) !important;
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
        color: white;
        margin-top: 10px;
        align-items: center;
        padding: 30px;

        @include media-breakpoint-down(md){
            flex-direction: column;
        }

        .user-initials{
            width: 10%;
            margin-right: 10px;

            @include media-breakpoint-down(md){
                width: 100%;
                display: flex;
            }

            .initials{
                font-size: 42px;
                font-weight: 700;
                line-height: 46px;
                width: 125px;
                height: 125px;
                margin-inline: -60px;
                background: #D5DDEC;
                color: #18207F;
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                @include media-breakpoint-down(md){
                    font-size: 34px;
                    line-height: 40px;
                    margin-inline: 0;
                    margin-top: -50px;
                    margin-right: 10px;
                    width: 108px;
                    height: 108px;
                }
            }

            .username{
                font-size: 26px;
                font-weight: 700;
                line-height: 30px;
                text-transform: uppercase;

                @include media-breakpoint-down(md){
                    font-size: 24px;
                    line-height: 20px;
                    width: 50%;
                }
            }
        }

        .user-information{
            width: 70%;

            @include media-breakpoint-down(md){
                width: 100%;
                padding-bottom: 15px;
            }

            .username{
                font-size: 26px;
                font-weight: 700;
                line-height: 30px;
                text-transform: uppercase;

                @include media-breakpoint-down(md){
                    font-size: 24px;
                    line-height: 20px;
                }
            }

            .level{
                padding: 10px 0px;

                &-trendsetter, &-hero, &-leader{
                    border-radius: 10px;
                    background: #0B1047;
                    border: 2px solid rgba(255, 255, 255, 0.25);
                    margin-right: 20px;
                    width: 250px;

                    &-lock{
                        width: 30px;
                        background: url('/content/dam/glo-it/images/insidersclub/level-lock.svg') no-repeat white 3px;
                        background-position: center;
                        border-radius: 8px;
                        margin-right: 20px;
                        align-self: center;
                        padding: 9px 0px;

                        @include media-breakpoint-down(md){
                            width: 40px;
                            min-width: 29px;
                        }

                        &-info{
                            background: transparent;

                            .number{
                                background-color: #0B1047;
                                width: 17px;
                                height: 17px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border-radius: 100%;
                                font-size: 12px;
                                line-height: 14px;
                                margin-right: 5px;
                                margin-left: -10px;
                            }
                        }
                    }

                    &-info{
                        padding: 10px;
                        border-radius: 8px;
                        width: 70%;
                        height: 36px;

                        &-level {
                            position: absolute;
                        }

                        .number{
                            background-color: #0B1047;
                            width: 17px;
                            height: 17px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 100%;
                            font-size: 12px;
                            line-height: 14px;
                            margin-right: 5px;
                            margin-left: -18px;
                        }
    
                        .label{
                            text-transform: uppercase;
                            font-size: 12px;
                            font-weight: 700;
                            line-height: 17px;
                            letter-spacing: 1.2px;
                        }
                    }
                }

                &-trendsetter-info {
                    background: linear-gradient(135deg, #B4C7E7 0%, #90AFDD 51.04%, #6D96D1 100%);
                }

                &-hero-info {
                    background: linear-gradient(135deg, #3FA4FC 0%, #2B82C4 51.04%, #196EAF 100%);
                }
                
                &-leader-info {
                    background: linear-gradient(135deg, #000AE3 0%, #18207F 51.04%, #0B1047 100%);
                }
            }

            .level-completion {
                .label {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: $white;
                }

                .percentage {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }

        .balance{
            width: 20%;
            flex-direction: column;

            @include media-breakpoint-down(md){
                width: 100%;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: flex-start;
                padding-top: 15px;
                border-top: 1px solid #D5DDEC;
            }

            .label{
                font-size: 20px;
                font-weight: 600;
                line-height: 28px;

                @include media-breakpoint-down(md){
                    width: 50%;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 28px;
                }
            }

            .balance-value{
                font-size: 42px;
                font-weight: 800;
                line-height: 46px;
                padding: 10px;
                background: #0B1047;
                border-radius: 4px;
                margin: 5px 0px;

                @include media-breakpoint-down(md){
                    width: 50%;
                    font-size: 28px;
                    font-weight: 800;
                    line-height: 24px;
                }
            }

            .balance-separator {
                flex-basis: 100%;
                height: 0;
            }

            .balance-total{
                font-size: 12px;
                font-weight: 700;
                line-height: 17px;
                letter-spacing: 0.2px;

                @include media-breakpoint-down(md){
                    width: 100%;
                    text-align: end;
                    margin-top: 10px;
                }
            }
        }
    }
    &-userLevel-skeleton{
        background: linear-gradient(135deg, #5F73BA 0%, #000AE3 100%);
        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
        color: white;
        margin-top: 10px;
        align-items: center;
        padding: 30px;

        @include media-breakpoint-down(md){
            flex-direction: column;
        }

        .user-initials{
            width: 10%;
            margin-right: 10px;

            @include media-breakpoint-down(md){
                width: 100%;
                display: flex;
            }

            .initials{
                font-size: 42px;
                font-weight: 700;
                line-height: 46px;
                width: 125px;
                height: 125px;
                margin-inline: -60px;
                background: #DEDEDE;
                color: #18207F;
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                @include media-breakpoint-down(md){
                    font-size: 34px;
                    line-height: 40px;
                    margin-inline: 0;
                    margin-top: -50px;
                    margin-right: 10px;
                    width: 108px;
                    height: 108px;
                }
            }

            .username{
                font-size: 26px;
                font-weight: 700;
                line-height: 30px;
                text-transform: uppercase;

                @include media-breakpoint-down(md){
                    font-size: 24px;
                    line-height: 20px;
                    width: 50%;
                }
            }
        }

        .user-information{
            width: 70%;

            @include media-breakpoint-down(md){
                width: 100%;
                padding-bottom: 15px;
            }

            .username{
                font-size: 26px;
                font-weight: 700;
                line-height: 30px;
                text-transform: uppercase;

                @include media-breakpoint-down(md){
                    font-size: 24px;
                    line-height: 20px;
                }
            }

            .level{
                padding: 10px 0px;

                &-trendsetter, &-hero, &-leader{
                    border-radius: 10px;
                    background: #0B1047;
                    border: 2px solid rgba(255, 255, 255, 0.25);
                    margin-right: 20px;
                    width: 250px;

                    &-lock{
                        width: 30px;
                        background: url('/content/dam/glo-it/images/insidersclub/level-lock.svg') no-repeat white 3px;
                        background-position: center;
                        border-radius: 8px;
                        margin-right: 20px;
                        align-self: center;
                        padding: 9px 0px;

                        @include media-breakpoint-down(md){
                            width: 40px;
                            min-width: 29px;
                        }

                        &-info{
                            background: transparent;

                            .number{
                                background-color: #0B1047;
                                width: 17px;
                                height: 17px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                border-radius: 100%;
                                font-size: 12px;
                                line-height: 14px;
                                margin-right: 5px;
                                margin-left: -10px;
                            }
                        }
                    }

                    &-info{
                        padding: 10px;
                        border-radius: 8px;
                        width: 70%;
                        height: 36px;

                        &-level {
                            position: absolute;
                        }

                        .number{
                            background-color: #0B1047;
                            width: 17px;
                            height: 17px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 100%;
                            font-size: 12px;
                            line-height: 14px;
                            margin-right: 5px;
                            margin-left: -18px;
                        }
    
                        .label{
                            text-transform: uppercase;
                            font-size: 12px;
                            font-weight: 700;
                            line-height: 17px;
                            letter-spacing: 1.2px;
                        }
                    }
                }

                &-trendsetter-info {
                    background: linear-gradient(135deg, #B4C7E7 0%, #90AFDD 51.04%, #6D96D1 100%);
                }

                &-hero-info {
                    background: linear-gradient(135deg, #3FA4FC 0%, #2B82C4 51.04%, #196EAF 100%);
                }
                
                &-leader-info {
                    background: linear-gradient(135deg, #000AE3 0%, #18207F 51.04%, #0B1047 100%);
                }
            }

            .level-completion {
                height: 63px;
                background: #DEDEDE;
                border-radius: 8px;

                .label {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    color: $white;
                }

                .percentage {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }

        .balance{
            width: 20%;
            flex-direction: column;

            @include media-breakpoint-down(md){
                width: 100%;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: flex-start;
                padding-top: 15px;
                border-top: 1px solid #D5DDEC;
            }

            .label{
                font-size: 20px;
                font-weight: 600;
                line-height: 28px;

                @include media-breakpoint-down(md){
                    width: 50%;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 28px;
                }
            }

            .balance-value{
                font-size: 42px;
                font-weight: 800;
                line-height: 46px;
                padding: 10px;
                background: #0B1047;
                border-radius: 4px;
                margin: 5px 0px;

                @include media-breakpoint-down(md){
                    width: 50%;
                    font-size: 28px;
                    font-weight: 800;
                    line-height: 24px;
                }
            }

            .balance-separator {
                flex-basis: 100%;
                height: 0;
            }

            .balance-total{
                font-size: 12px;
                font-weight: 700;
                line-height: 17px;
                letter-spacing: 0.2px;

                @include media-breakpoint-down(md){
                    width: 100%;
                    text-align: end;
                    margin-top: 10px;
                }
            }
        }
    }

    .mission-container {
        margin-top: 47px;
        margin-bottom: 30px;

        @include media-breakpoint-down(md) {
            margin-top: 24px;
        }

        .mission-card {

            &-content {
                cursor: pointer;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 16px;
                gap: 16px;
                background: #FFFFFF;
                border: 3px solid #18207F;
                filter: drop-shadow(8px 8px 0px #D5DDEC);
            }

            .mission-image-section {
                position: relative;
                width: 100%;

                .mission-image {
                    img {
                        aspect-ratio: 5/4;
                        object-fit: cover;
                        object-position: bottom center;
                        width: 100%;
                    }
                }

                .mission-value {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 8px;
                    gap: 15px;
                    width: max-content;
                    background: linear-gradient(45deg, #5482C2 0, #353174 100%) !important;

                    .mission-coin {
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 28px;
                        text-align: center;
                        letter-spacing: 0.2px;
                        color: $white;

                        .coin-pretext {
                            font-size: 12px;
                            line-height: 17px;
                            margin-right: 5px;
                        }
                    }

                    .mission-euros {
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 17px;
                        text-align: center;
                        letter-spacing: 0.2px;
                        color: #D5DDEC;
                    }
                }

                .mission-type {
                    display: flex;
                    align-items: center;
                    padding: 4px 8px;
                    gap: 8px;
                    position: absolute;
                    background: $white;
                    left: 11.61px;
                    top: 12px;
                    border: 1px solid #3A3A3A;
                    border-radius: 8px;
                    font-size: 14px;
                    line-height: 20px;
                }
            }

            .mission-title-section {
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                .mission-title {
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 28px;
                    letter-spacing: 0.2px;
                    color: #2A2C2C;
                }
            }
        }
    }

    .mission-container-skeleton {
        margin-top: 47px;
        margin-bottom: 30px;

        display: flex;
        flex-direction: row;
        gap: 14px;
        width: 180%;

        @include media-breakpoint-down(md) {
            margin-top: 24px;
        }

        .mission-card {
            &-content {
                display: flex;
                flex-direction: column;
                gap: 8px;
            }


            .mission-image-section {
                position: relative;
                width: 100%;

                .mission-image {
                    aspect-ratio: 5/4;
                    background: #EFEFEF;
                    border-radius: 12px;
                }

                .mission-value {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 8px;
                    gap: 15px;
                    width: max-content;
                    background: linear-gradient(45deg, #5482C2 0, #353174 100%) !important;

                    .mission-coin {
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 28px;
                        text-align: center;
                        letter-spacing: 0.2px;
                        color: $white;

                        .coin-pretext {
                            font-size: 12px;
                            line-height: 17px;
                            margin-right: 5px;
                        }
                    }

                    .mission-euros {
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 17px;
                        text-align: center;
                        letter-spacing: 0.2px;
                        color: #D5DDEC;
                    }
                }

                .mission-type {
                    display: flex;
                    align-items: center;
                    padding: 4px 8px;
                    gap: 8px;
                    position: absolute;
                    background: $white;
                    left: 11.61px;
                    top: 12px;
                    border: 1px solid #3A3A3A;
                    border-radius: 8px;
                    font-size: 14px;
                    line-height: 20px;
                }
            }

            .mission-title-section-skeleton {
                
                &-first{
                    display: block;
                    background: #EFEFEF;
                    border-radius: 4px;
                    height: 20px;
                    margin-top: 8px;
                }
                &-second, &-third {
                    display: block;
                    background: #EFEFEF;
                    border-radius: 4px;
                    height: 12px;
                }

            }
        }
    }

    &.mobile-app {
        @include media-breakpoint-down(lg){
            width: auto;
            margin: 0;
        }

        .loading-wrapper {
            margin: 50px auto;
            align-items: center;
            display: flex;
            height: 110px;
            position: relative;
            width: 100px;

            p {
                text-align: center;
                width: 100%;
                font-size: 14px;
                margin: 0;
            }

            &:after {
                -webkit-animation: spin 2s linear infinite; /* Safari */
                animation: spin 2s linear infinite;
                border: 10px solid $light-gray;
                border-radius: 50%;
                border-top: 10px solid $purple;
                bottom: 0;
                content: "";
                height: 60px;
                left: 0;
                margin: auto;
                position: absolute;
                right: 0;
                top: 0;
                width: 60px;
                z-index: 1;
            }
        }

        .comefunziona-link {
            @include media-breakpoint-down(md) {
                width: 100%;
                display: flex;
                justify-content: flex-end;
                padding: 20px 20px 0 20px;
            }
        }

        .bat-insidersclub-gloit-userLevel{

            @include media-breakpoint-down(md) {
                border-radius: 0px 0px 20px 20px;
                margin-top: 0;
                padding: 20px;
            }

            .user-initials{
    
                @include media-breakpoint-down(md){
                    align-items: center;
                }
    
                .initials{
                    @include media-breakpoint-down(md){
                        margin-top: 0;
                        width: 96px;
                        height: 96px;
                    }
                }
    
                .username{
    
                    @include media-breakpoint-down(md){
                        font-size: 20px;
                        line-height: 26px;
                        text-transform: unset;
                    }
                }
            }
    
            .user-information{
                width: 70%;
    
                @include media-breakpoint-down(md){
                    width: 100%;
                    padding-bottom: 15px;
                }
    
                .username{
                    font-size: 26px;
                    font-weight: 700;
                    line-height: 30px;
                    text-transform: uppercase;
    
                    @include media-breakpoint-down(md){
                        font-size: 24px;
                        line-height: 20px;
                    }
                }
    
                .level{
    
                    &-trendsetter, &-hero, &-leader{
                        background: rgba(255, 255, 255, 0.2);
                        border: unset;
    
                        &-info{
        
                            .label{
                                @include media-breakpoint-down(md) {
                                    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
                                }
                            }
                        }
                    }

                    &-hero-lock,
                    &-leader-lock {
                        background: url('/content/dam/glo-it/images/insidersclub/level-lock.svg') no-repeat rgba(255, 255, 255, 0.2) 8px;
                    }
                }
            }
    
            .balance{
    
                @include media-breakpoint-down(md){
                    border-top: unset;
                    padding-top: 0;
                    align-items: center;
                }
    
                .label,
                .total-label{
                    @include media-breakpoint-down(md){
                        width: auto;
                        font-size: 12px;
                        font-weight: 700;
                        line-height: 15px;
                        text-align: center;
                        color: #D5DDEC;
                        letter-spacing: 1.2px;
                    }
                }
    
                .balance-value,
                .total-value{
                    @include media-breakpoint-down(md){
                        width: auto;
                        font-size: 28px;
                        font-weight: 700;
                        line-height: 36px;
                        padding: 0;
                        background: unset;
                        border-radius: 0;
                        margin: 0;
                        display: flex;
                        justify-content: center;
                        gap: 3px;
                    }
                }
    
                .balance-separator {
                    flex-basis: auto;
                    height: 71px;
                    opacity: 0.2;
                    border: 2px solid #FFFFFF;
                }
    
                .balance-total,
                .balance-available{ 
                    @include media-breakpoint-down(md){
                        width: auto;
                        text-align: center;
                        margin-top: 0;
                    }
                }
            }
        }


        .bat-insidersclub-gloit-userLevel-skeleton{
            background: #EFEFEF;
            
            @include media-breakpoint-down(md) {
                border-radius: 0px 0px 20px 20px;
                margin-top: 0;
                padding: 20px;
            }

            .user-initials{
    
                @include media-breakpoint-down(md){
                    align-items: center;
                }
    
                .initials{
                    @include media-breakpoint-down(md){
                        margin-top: 0;
                        width: 96px;
                        height: 96px;
                    }
                }
    
                .username{
                    
                    @include media-breakpoint-down(md){
                        width: 224.51px;
                        height: 16px;
                        background: #DEDEDE;
                        border-radius: 4px;
                    }
                }
            }
    
            .user-information{
                width: 70%;
    
                background: #DEDEDE;
                border-radius: 8px;
                height: 63px;
                margin-bottom: 20px;
                margin-top: 20px;

                @include media-breakpoint-down(md){
                    width: 100%;
                    padding-bottom: 15px;
                }
    
                .username{
                    font-size: 26px;
                    font-weight: 700;
                    line-height: 30px;
                    text-transform: uppercase;
    
                    @include media-breakpoint-down(md){
                        font-size: 24px;
                        line-height: 20px;
                    }
                }
    
                .level{
    
                    &-trendsetter, &-hero, &-leader{
                        background: rgba(255, 255, 255, 0.2);
                        border: unset;
    
                        &-info{
        
                            .label{
                                @include media-breakpoint-down(md) {
                                    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
                                }
                            }
                        }
                    }

                    &-hero-lock,
                    &-leader-lock {
                        background: url('/content/dam/glo-it/images/insidersclub/level-lock.svg') no-repeat rgba(255, 255, 255, 0.2) 8px;
                    }
                }
            }
    
            .balance{
                background: #DEDEDE;
                @include media-breakpoint-down(md){
                    border-top: unset;
                    padding-top: 0;
                    align-items: center;
                }
    
                .label,
                .total-label{
                    @include media-breakpoint-down(md){
                        width: auto;
                        font-size: 12px;
                        font-weight: 700;
                        line-height: 15px;
                        text-align: center;
                        color: #D5DDEC;
                        letter-spacing: 1.2px;
                    }
                }
    
                .balance-value,
                .total-value{
                    @include media-breakpoint-down(md){
                        width: auto;
                        font-size: 28px;
                        font-weight: 700;
                        line-height: 36px;
                        padding: 0;
                        background: unset;
                        border-radius: 0;
                        margin: 0;
                        display: flex;
                        justify-content: center;
                        gap: 3px;
                    }
                }
    
                .balance-separator {
                    flex-basis: auto;
                    height: 71px;
                    opacity: 0.2;
                    border: 2px solid #FFFFFF;
                }
    
                .balance-total,
                .balance-available{ 
                    @include media-breakpoint-down(md){
                        width: auto;
                        text-align: center;
                        margin-top: 0;
                    }
                }
            }
        }

        .bat-insidersclub-gloit-title {
            padding-right: 40px;
            margin-top: 25px;
        }

        .bat-insidersclub-gloit-title-skeleton{
            padding-right: 20px;
            margin-top: 5px;
        }

        &.uk-slider-container {
            padding-left: 20px;
        }

        .mission-container {

            &.mobile-app {
                @include media-breakpoint-down(md) {
                    padding: 0 20px;
                }
            }

            .mission-card {

                &-content {
                    @include media-breakpoint-down(md) {
                        border: unset;
                        filter: none;
                        padding: 0;
                        gap: 10px;
                        width: 100%;
                    }
                }
    
                .mission-image-section {
    
                    .mission-image {
                        img:not([src*='ottieniGoldApp.png']) {
                            @include media-breakpoint-down(md) {
                                aspect-ratio: 5/4;
                                object-fit: cover;
                                object-position: bottom center;
                                box-shadow: 0px 4px 6px -1px #D5DDEC, 0px 2px 4px -1px rgba(213, 221, 236, 0.06);
                                border-radius: 12px;

                                bat-productlisting-gloitinsidersshopcarousel & {
                                    object-position: top center;
                                }
                            }
                        }

                        img[src*='ottieniGoldApp.png'] {
                            aspect-ratio: unset;
                        }
                    }
    
                    .mission-value {
                        @include media-breakpoint-down(md) {
                            background: linear-gradient(45deg, #5482C2 0, #353174 100%) !important;
                            border-radius: 8px;
                            position: absolute;
                            bottom: 12px;
                            right: 12px;
                        }
                    }
    
                    .mission-type {
                        @include media-breakpoint-down(md) {
                            border: unset;
                        }
                    }
                }

                .mission-title {
                    h1,
                    h2,
                    h3 {
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 28px;
                        letter-spacing: .2px;
                        color: #2a2c2c;
                    }

                    p {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        color: #3A3A3A;

                        &:last-child {
                            overflow: hidden;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 3;
                            white-space: pre-wrap;
                        }
                    }
                }
            }
        }
        .mission-container-skeleton {
            margin-top: 47px;
            margin-bottom: 30px;
    
            display: flex;
            flex-direction: row;
            gap: 14px;
            width: 180%;
    
            @include media-breakpoint-down(md) {
                margin-top: 24px;
            }
    
            .mission-card {
                &-content {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                }
    
    
                .mission-image-section {
                    position: relative;
                    width: 100%;
    
                    .mission-image {
                        aspect-ratio: 5/4;
                        background: #EFEFEF;
                        border-radius: 12px;
                    }
    
                    .mission-value {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        padding: 8px;
                        gap: 15px;
                        width: max-content;
                        background: linear-gradient(45deg, #5482C2 0, #353174 100%) !important;
    
                        .mission-coin {
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 28px;
                            text-align: center;
                            letter-spacing: 0.2px;
                            color: $white;
    
                            .coin-pretext {
                                font-size: 12px;
                                line-height: 17px;
                                margin-right: 5px;
                            }
                        }
    
                        .mission-euros {
                            font-weight: 700;
                            font-size: 12px;
                            line-height: 17px;
                            text-align: center;
                            letter-spacing: 0.2px;
                            color: #D5DDEC;
                        }
                    }
    
                    .mission-type {
                        display: flex;
                        align-items: center;
                        padding: 4px 8px;
                        gap: 8px;
                        position: absolute;
                        background: $white;
                        left: 11.61px;
                        top: 12px;
                        border: 1px solid #3A3A3A;
                        border-radius: 8px;
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
    
                .mission-title-section-skeleton {
                    
                    &-first{
                        display: block;
                        background: #EFEFEF;
                        border-radius: 4px;
                        height: 20px;
                        margin-top: 8px;
                    }
                    &-second, &-third {
                        display: block;
                        background: #EFEFEF;
                        border-radius: 4px;
                        height: 12px;
                    }
    
                }
            }
        }

        .mission-container-missions-skeleton {
            margin-top: 47px;
            margin-bottom: 30px;
    
            display: flex;
            flex-direction: column;
            gap: 14px;
            width: 118%;
    
            @include media-breakpoint-down(md) {
                margin-top: 24px;
            }

            &.mobile-app {
                @include media-breakpoint-down(md) {
                    padding: 0 20px;
                }
            }
    
            .mission-card {
                &-content {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                }
    
    
                .mission-image-section {
                    position: relative;
                    width: 100%;
    
                    .mission-image {
                        aspect-ratio: 5/4;
                        background: #EFEFEF;
                        border-radius: 12px;
                    }
    
                    .mission-value {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        padding: 8px;
                        gap: 15px;
                        width: max-content;
                        background: linear-gradient(45deg, #5482C2 0, #353174 100%) !important;
    
                        .mission-coin {
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 28px;
                            text-align: center;
                            letter-spacing: 0.2px;
                            color: $white;
    
                            .coin-pretext {
                                font-size: 12px;
                                line-height: 17px;
                                margin-right: 5px;
                            }
                        }
    
                        .mission-euros {
                            font-weight: 700;
                            font-size: 12px;
                            line-height: 17px;
                            text-align: center;
                            letter-spacing: 0.2px;
                            color: #D5DDEC;
                        }
                    }
    
                    .mission-type {
                        display: flex;
                        align-items: center;
                        padding: 4px 8px;
                        gap: 8px;
                        position: absolute;
                        background: $white;
                        left: 11.61px;
                        top: 12px;
                        border: 1px solid #3A3A3A;
                        border-radius: 8px;
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
    
                .mission-title-section-skeleton {
                    
                    &-first{
                        display: block;
                        background: #EFEFEF;
                        border-radius: 4px;
                        height: 20px;
                        margin-top: 8px;
                    }
                    &-second, &-third {
                        display: block;
                        background: #EFEFEF;
                        border-radius: 4px;
                        height: 12px;
                    }
    
                }
            }
        }

        .blog-mission-card-skeleton{
            min-height: 500px;
            background-color: #EFEFEF;
            margin: 20px;
            border-radius: 25px;
            padding: 1px;
            .mission-image-section-skeleton{
                min-height: 315px;
                margin: 20px;
                border-radius: 20px;
                background-color: #d6d4d4;
            }
            .mission-title-section-skeleton{
                margin: 20px;
                border-radius: 20px;
                min-height: 175px;
                background-color: #d6d4d4;
            }
        }
        
        .mobileapp-shopcarousel-mission-card-content-first,.mobileapp-shopcarousel-mission-card-content-second{
            padding-left: 0px !important;
            margin: 20px;
            .mobileapp-shopcarousel-mission-image-section{
                width: 345px;
                min-height: 275px;
                border-radius: 20px;
                background-color: #EFEFEF;
            }
            .mobileapp-shopcarousel-mission-title-section{
                width: 345px;
                min-height: 28px;
                border-radius: 20px;
                margin-top: 10px;
                background-color: #EFEFEF;
            }
        }
        .mobileapp-shopcarousel-mission-card-content-second{
            margin-left: 0px;
        }
    }
}

#sectionInsidersClub{
    .bat-insidersclub-gloit{
        width: 87%;
        margin: auto;
    }
 }

.icon-mgm {
    &::before {
        content: url('/content/dam/glo-it/images/newloyalty/mgm.svg');
        height: 38px;
    }
}

.icon-redeem {
    &::before {
        content: url('/content/dam/glo-it/images/newloyalty/redeem.svg');
        height: 34px;
    }
}

.icon-survey {
    &::before {
        content: url('/content/dam/glo-it/images/newloyalty/survey.svg');
        height: 35px;
    }
}

.icon-blog {
    &::before {
        content: url('/content/dam/glo-it/images/newloyalty/blog.svg');
        height: 35px;
    }
}

.icon-consent {
    &::before {
        content: url('/content/dam/glo-it/images/newloyalty/consent.svg');
        height: 35px;
    }
}

.icon-reviews {
    &::before {
        content: url('/content/dam/glo-it/images/newloyalty/reviews.svg');
        height: 35px;
    }
}

.icon-generic {
    &::before {
        content: url('/content/dam/glo-it/loyalty-missions/gift-box.png');
        height: auto;
        transform: scale(0.15);
    }
}

.icon-information {
    &::before {
        content: url('/content/dam/glo-it/images/newloyalty/informationIcon.svg');
        height: 50px;
        transform: scale(0.5);
    }
}

.icon-prediction {
    &::before {
        content: url('/content/dam/glo-it/images/newloyalty/prediction.svg');
        height: 58px;
        transform: scale(0.5);
    }
}

.icon-download {
    &::before {
        content: url('/content/dam/glo-it/images/newloyalty/downloadIcon.svg');
        height: 50px;
        transform: scale(0.5);
    }
}

.icon-cup {
    &::before {
        content: url('/content/dam/glo-it/images/newloyalty/Trophy.svg');
        height: 35px;
        transform: scale(1);
    }
}


.icon-quiz {
    &::before {
        content: url('/content/dam/glo-it/images/newloyalty/quiz.svg');
        height: 35px;
        transform: scale(0.7);
    }
}

.icon-wrong {
    &::before {
        content: url('/content/dam/glo-it/images/newloyalty/wrongAnswer.svg');
        height: 35px;
    }
}

.icon-video {
    &::before {
        content: url('/content/dam/glo-it/images/newloyalty/videoIcon.svg');
        height: 50px;
        transform: scale(0.5);
    }
}

.icon-picture {
    &::before {
        content: url('/content/dam/glo-it/images/mobileapp/camera.svg');
        height: 50px;
        transform: scale(0.6);
    }
}

.icon-fancam {
    &::before {
        content: url('/content/dam/glo-it/images/mobileapp/fancam.svg');
        height: 50px;
        transform: scale(0.6);
    }
}

.icon-qrcode {
    &::before {
        content: url('/content/dam/glo-it/images/mobileapp/QR.svg');
        height: 49px;
        transform: scale(0.6);
    }
}


.toggle-card-modal-bkg {
        background: linear-gradient(to right, #fd612c 0%, #ce3a07 100%);
        height: 350px;
        border-radius: 7%;
        }
.membership-card {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 3%;
    
    &-modal-text{
        text-align: center;
        color: #fff;
        font-weight: bold;
    }

    &-eyeicon {
        margin-left: 2%;
        width: 6%;
    }
}