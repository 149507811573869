.gloit-thr-card{
	display: flex;
    flex-direction: column;
    padding: 0;
    background: #fff;
    box-shadow: 8px 4px 10px #E0E0E0;
    height: 100%;
    border-radius: 23px;
    margin: 2%;
    min-height: 486px;
    max-width: 100%;
    
    &-img-container{
    	background-color: #E0E0E0;
    	border-top-left-radius: 23px;
		border-top-right-radius: 23px;
    }
    &-img{
    	border-top-left-radius: 23px;
		border-top-right-radius: 23px;
		height: 100%;
		width: 100%;
    }
}

.toggle-show-card-cnt{
	display: flex;
	justify-content: center;
  margin-top: 24px;
    &-btn{
    border: solid 3px #1B65AC;
    color: #1B65AC;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    border-radius: 100px;
    padding: 13px 38px;
    }
}

.gloit-thr-card-content-container{
    display: flex;
    flex-direction: column;
    margin:10% 8% 0 8%;

    .gloit-thr-card-body{
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 10px;
    }
      
    .gloit-thr-card-subtitle p{
      margin-bottom: 2%;
      font-size: 10px;
      color: #979797;
    }
      
    .gloit-thr-card-title{
        width: 100%;
        text-align: left;
      
        p{
          width: 100%;
          line-height: 20px;
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 5%;
          @include media-breakpoint-down(sm) {
            width: 100%;
          }
        }
    }
    
    .gloit-thr-card-body p{
          color: #979797;
    }
}

.thr-card-image-height{

}

@media only screen and (max-width: 767px) {
	.gloit-thr-card{
        min-height: 585px;
        box-shadow: 6px 6px 10px #e0e0e0, -2.5px 4px 5px #e0e0e0;
        
        &-content-container{
        	margin: 8%;
        }
        &-img-container{
    		max-height: 240px;
    		overflow: hidden;
        }
        &-subtitle{
        	margin-bottom: 4%;
        }
        &-title{
        	margin-bottom: 5%;
        }
        &-body p{
        	line-height: 22px;
        }
    }
}

bat-section-glorsthreegrid{
	&.thr-fit-cards{
		.bat-section-gloit{
			 display: flex;
             flex-direction: row;
             justify-content: space-evenly;
             .card{
             	min-width: 320px
             }
         }
         .gloit-thr-card-content-container{
         	display: flex;
			    flex-direction: column;
			    align-items: center;
          height: 100%;
         }
         .gloit-thr-card-subtitle{
             display: flex;
             width: 170px;
             flex-direction: column;
             align-items: flex-start;
         }
	}
}