.bat-editaddress-gloit{
    width: 75%;
    margin: auto 70px;

    @include media-breakpoint-down(md){
        width: auto;
        margin: auto 20px;
    }

    &-title{
        padding-bottom: 40px;
        border-bottom: 2px solid #DEDEDE;

        @include media-breakpoint-down(md){
            padding-bottom: 30px;
            text-align: center;
        }

        .title{
            @include font-rem(30px, 34px);
            text-transform: uppercase;
            margin-bottom: 30px; 

            @include media-breakpoint-down(md){
                @include font-rem(24px, 28px);
                margin-bottom: 20px;
            }
        }

        .backToAddresses{
            text-transform: uppercase;

            &::before{
                content: "\e910";
                font-family: "icomoon";
                font-size: 26px;
                position: inherit;
                transform: rotate(180deg);
                padding-top: 5px;
            }

            &::after{
                display: none;
            }
        }
    }

    .bat-form-step{
        .contact-information,
        .address,
        .address-information{
            label{
                text-transform: uppercase;
            }

            input,
            .select-wrapper{
                width: 55%;
                -webkit-appearance: none;
                border-radius: 0px;
                -webkit-border-radius: 0px;

                @include media-breakpoint-down(md){
                    width: 100%;
                }
            }
        }

        .address,
        .address-information{
            &.hidden{
                display: none;
            }
        }

        .contact-information,
        .address,
        .address-information{
            .bat-form--add-address-messages{
                .bat-message-text{
                    @include font-rem(20px ,20px);

                    @include media-breakpoint-down(md){
                        @include font-rem(18px ,24px);
                    }
                }
            }

            .bat-form--add-address-enter-address{
                span{
                    text-transform: uppercase;
                }
            }
        }

        .contact-information{
            padding-top: 40px;
            padding-bottom: 30px;
            border-bottom: 2px solid #DEDEDE;
        }

        .address,
        .address-information{
            padding-top: 30px;
            padding-bottom: 20px;
            margin-bottom: 0px;
        }

        .address-information{
            .bat-form--add-address-company-name{
               width: 55%;

               @include media-breakpoint-down(md){
                width: 100%;
               }

               input{
                   width: 100%;
               }
            }
        }

        .checkbox-default-addresses{
            width: 55%;
            margin-bottom: 30px;

            @include media-breakpoint-down(md){
                width: 100%;
            }

            .bat-form--add-address-default-delivery-address,
            .bat-form--add-address-default-billing-address{
                margin: 0px;
                padding: 20px;
                border: 2px solid #DEDEDE;

                fieldset{
                    margin: 0px;
                    
                    label{
                        margin: 0px;

                        input{
                            -webkit-appearance: none;
                            margin-right: 0px;
                            background: white;

                            &:focus{
                                border: none;
                            }
                        }

                        span{
                            padding: 0px;
                        }
                    }
                }
            }

            .bat-form--add-address-default-delivery-address{
                margin-bottom: 15px;
            }
        }

        .submit{
            button{
                width: 250px;

                @include media-breakpoint-down(md){
                    max-width: 100%;
                    width: max-content;
                }
            }
        }
    }
    .bat-form-field.bat-form--add-address-country, 
    .bat-form-block.checkbox-default-addresses {
        display: none;
    }
}