.bat-new-signup-gloit {
    margin-inline: 15%;

    @include media-breakpoint-down(sm) {
        margin-inline: 0px;
    }

    .title {
        margin-top: 30px;
        font-size: 30px;

        @include media-breakpoint-down(sm) {
            margin-inline: 20px;
            margin-bottom: 25px;
        }


    }

    &-tabs {
        flex-direction: column-reverse;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }

        .tabs {
            justify-content: center;

            .tab {
                width: 100%;
                @include font-rem(13px, 15px);
                font-weight: 700;
                color: #6B6B6B;
                font-family: $primary-font;
                text-transform: uppercase;
                padding: 20px;
                border-bottom: 2px solid #DEDEDE;

                @include media-breakpoint-down(sm) {
                    @include font-rem(11px, 13px);
                    background-color: #EFEFEF;
                    border-bottom: none;
                }

                &.active {
                    color: #CD4A04;
                    border-bottom: 2px solid #CD4A04;
                }
            }
        }

        .title {
            @include font-rem(30px, 34px);
            text-transform: uppercase;
            text-align: center;
            margin-bottom: 70px;

            @include media-breakpoint-down(sm) {
                @include font-rem(20px, 28px);
                margin-bottom: 30px;
            }
        }
    }

    &-form {
        margin-top: 40px;

        @include media-breakpoint-down(sm) {
            margin-inline: 20px;
            margin-top: 0px;
        }

        .g-recaptcha {
            display: none;

            &.active {
                display: block;
            }
        }

        &-otp-title {
            text-align: center;
            margin-bottom: 20px;
        }

        .bat-form-block {
            display: none;

            &.active {
                display: block;

                &.submit {
                    display: flex;
                    margin-top: 10px;
                }
            }

            .bat-form-container {

                &-minor{
                    padding-block: 3%;
                    padding-left: 4%;
                    padding-right: 8%;
                    width: 100%;
                    height: auto;
                    border-radius: 40px;
                    background: #F7F7F7;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @include media-breakpoint-down(sm) {
                        padding-block: 7%;
                        padding-left: 8%;
                        padding-right: 6%;
                    }
                    &-flex{
                        display: flex;
                        align-items: flex-start;
                        gap: 30px;
                        @include media-breakpoint-down(sm) {
                            flex-direction: column;
                        }
                        &-title{
                            display: block;
                            &-upper{
                                font-size: 24px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: 130%; 
                                background: var(--Glo-CTA-Gradient, linear-gradient(268deg, #CD4A04 0.65%, #ED7D30 100%));
                                background-clip: text;
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                            }
                            &-lower{
                                color: var(--Glo-Brand-Black, #2A2C2C);
                                font-size: 21px;
                                font-style: normal;
                                font-weight: 800;
                                line-height: 130%; 
                            }
                        }
                        &-description{
                            color: var(--Neutral-Charcoal, #3A3A3A);
                            font-size: 13px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 150%;
                            span{
                                font-weight: 800;
                                &.subtitle{
                                    font-size: 14px;
                                }
                            }
                            br {
                                display: block; 
                                content: "";
                                margin-top: 12px; 
                            }
                        }
                    }
                }

                &-fullname,
                &-date,
                &-id,
                &-address,
                &-phonenumber,
                &-validateotp,
                &-city,
                &-nation,
                &-email,
                &-password,
                &-product-choice,
                &-product-other-choices {
                    display: flex;

                    div:first-of-type {
                        margin-right: 20px;
                    }

                    div:last-of-type {
                        margin-left: 20px;
                    }

                    div:only-child {
                        margin-right: 0;
                        margin-left: 0;
                    }

                    div {
                        input {
                            border-radius: 0px;
                        }
                    }

                    label {
                        &::after {
                            content: '*';
                            color: red;
                        }
                    }
                }

                &-date,
                &-city,
                &-nation,
                &-password,
                &-product-choice {
                    .bat-form-- {

                        &-genre,
                        &-province,
                        &-nation,
                        &-product-choice-1,
                        &-product-choice-2,
                        &-product-choice-3 {
                            .select-wrapper {
                                margin-inline: 0px;

                                select {
                                    -webkit-appearance: none;
                                    -webkit-border-radius: 0;
                                    border-radius: 0;
                                }
                            }
                        }
                    }

                    @include media-breakpoint-down(sm) {
                        div:first-of-type {
                            margin-right: 10px;
                        }

                        div:last-of-type {
                            margin-left: 10px;
                        }

                        div:only-child {
                            margin-right: 0;
                            margin-left: 0;
                        }

                        .bat-form-field {
                            input {
                                -webkit-appearance: none;
                            }
                        }
                    }
                }

                &-product-other-choices {
                    .bat-form-- {

                        &-first-glo-device,
                        &-region-of-origin {
                            .select-wrapper {
                                margin-inline: 0px;

                                select {
                                    -webkit-appearance: none;
                                    -webkit-border-radius: 0;
                                    border-radius: 0;
                                }
                            }
                        }
                    }

                    @include media-breakpoint-down(sm) {
                        div:first-of-type {
                            margin-right: 10px;
                        }

                        div:last-of-type {
                            margin-left: 10px;
                        }

                        div:only-child {
                            margin-right: 0;
                            margin-left: 0;
                        }

                        .bat-form-field {
                            input {
                                -webkit-appearance: none;
                            }
                        }
                    }
                }


                &-date {
                    .bat-form---genre {
                        label {
                            &::after {
                                content: '';
                            }
                        }
                    }

                    .bat-form---date {
                        .error-msg {
                            display: none;
                        }

                        .bat-icon {
                            margin-right: 10px;
                            margin-left: auto;
                            margin-top: -38px;
                            cursor: pointer;
                        }
                    }

                    input[type="date"]::-webkit-calendar-picker-indicator {
                        display: none;
                    }

                    input::-webkit-date-and-time-value {
                        text-align: left;
                        vertical-align: middle;
                    }

                    .bat-icon {
                        &::before {
                            content: url('/content/dam/glo-it/images/icon-calendar.svg');
                        }
                    }

                    input {
                        color: transparent;
                    }
                }

                &-date {
                    .bat-form---genre {
                        label {
                            &::after {
                                content: '';
                            }
                        }
                    }

                    .bat-form---date {
                        .error-msg {
                            display: none;
                        }

                        .bat-icon {
                            margin-right: 10px;
                            margin-left: auto;
                            margin-top: -38px;
                            cursor: pointer;
                        }
                    }

                    input[type="date"]::-webkit-calendar-picker-indicator {
                        display: none;
                    }

                    input::-webkit-date-and-time-value {
                        text-align: left;
                        vertical-align: middle;
                    }

                    .bat-icon {
                        &::before {
                            content: url('/content/dam/glo-it/images/icon-calendar.svg');
                        }
                    }

                    input {
                        color: transparent;
                    }
                }

                &-id.cardType,
                &-email.cvv {
                    .bat-form-field--read-only::before {
                        content: "";
                    }

                    .bat-form---cardType,
                    .bat-form---cvv {
                        .error-msg {
                            display: none;
                        }

                        .bat-icon {
                            margin-right: 10px;
                            margin-left: auto;
                            margin-top: -38px;
                        }
                    }

                    .bat-form---cardType {
                        label::after {
                            content: "";
                        }

                        .bat-icon {
                            &::before {
                                content: url('/content/dam/glo-it/images/checkout/card.svg');
                            }
                        }
                    }

                    .bat-form---cvv {
                        .bat-icon {
                            &::before {
                                content: url('/content/dam/glo-it/images/checkout/cvv.svg');
                                font-size: 40px;
                            }
                        }
                    }
                }

                &-email.discountCode {
                    .bat-form---discountCode {
                        label::after {
                            content: "";
                        }

                        @include media-breakpoint-up(sm) {
                            flex: 1;
                        }
                    }

                    .bat-form---applyCode {
                        margin-top: 20px;
                        margin-bottom: 30px;

                        @include media-breakpoint-up(sm) {
                            width: max-content;
                        }

                        .bat-button {
                            padding: 10px 21px;

                            @include media-breakpoint-down(sm) {
                                width: 100%;
                                max-width: unset;
                            }

                            &.bat-button--secondary--dark:hover span {
                                color: $brand-black !important;
                            }
                        }
                    }
                }

                &-fullname,
                &-id,
                &-address,
                &-phonenumber,
                &-validateotp,
                &-email,
                &-password,
                &-product-choice {
                    @include media-breakpoint-down(sm) {
                        display: block;

                        div:first-of-type {
                            margin-right: 0px;
                        }

                        div:last-of-type {
                            margin-left: 0px;
                        }
                    }
                }

                &-phonenumber,
                &-validateotp {
                    button {
                        margin-top: 14px;
                    }
                }

                &-email,
                &-password {
                    .bat-form---password {
                        .error-msg {
                            display: none;
                        }

                        .bat-icon {
                            margin-right: 10px;
                            margin-left: auto;
                            margin-top: -34px;
                            cursor: pointer;
                        }
                    }
                }

                &-email {
                    .password-meter {
                        border: 2px solid $concrete;
                        @include font-rem(14px, 20px);
                        margin: 0px;
                        margin-top: 5px;
                        margin-right: 0px !important;
                        padding: 5px 10px;

                        &.no-password {
                            background: $white
                        }

                        &.weak {
                            background: -webkit-linear-gradient(left, #ffafae 25%, white 25%);
                            background: -moz-linear-gradient(left, #ffafae 25%, white 25%);
                            background: -o-linear-gradient(left, #ffafae 25%, white 25%);
                            background: -ms-linear-gradient(left, #ffafae 25%, white 25%);
                            background: linear-gradient(left, #ffafae 25%, white 25%);
                        }

                        &.medium {
                            background: -webkit-linear-gradient(left, #ffd6b3 50%, white 50%);
                            background: -moz-linear-gradient(left, #ffd6b3 50%, white 50%);
                            background: -o-linear-gradient(left, #ffd6b3 50%, white 50%);
                            background: -ms-linear-gradient(left, #ffd6b3 50%, white 50%);
                            background: linear-gradient(left, #ffd6b3 50%, white 50%);
                        }

                        &.strong {
                            background: -webkit-linear-gradient(left, #c5eeac 75%, white 75%);
                            background: -moz-linear-gradient(left, #c5eeac 75%, white 75%);
                            background: -o-linear-gradient(left, #c5eeac 75%, white 75%);
                            background: -ms-linear-gradient(left, #c5eeac 75%, white 75%);
                            background: linear-gradient(left, #c5eeac 75%, white 75%);
                        }

                        &.very-strong {
                            background: -webkit-linear-gradient(left, #81b562 100%, white 100%);
                            background: -moz-linear-gradient(left, #81b562 100%, white 100%);
                            background: -o-linear-gradient(left, #81b562 100%, white 100%);
                            background: -ms-linear-gradient(left, #81b562 100%, white 100%);
                            background: linear-gradient(left, #81b562 100%, white 100%);
                        }
                    }
                }

                &-password {
                    .password-meter {
                        display: none;
                    }
                }

                &-id {
                    .bat-form---friend-code {
                        label {
                            &::after {
                                content: '';
                            }
                        }
                    }
                }

                &-city {
                    .bat-form---province {
                        label {
                            &::after {
                                content: '';
                            }
                        }
                    }
                }

                &-password {
                    .bat-form---province {
                        label::after {
                            content: "";
                        }
                    }
                }

                &-address {
                    .bat-form---friend-code {
                        label::after {
                            content: "";
                        }
                    }

                    .bat-form---product-choice-1 {
                        margin-left: 0px;

                        .select-wrapper {
                            margin: 0px;
                        }
                    }
                }

                &-continue {
                    margin-top: 30px;
                    text-align: right;
                    display: flex;

                    button {
                        width: auto;

                        @include media-breakpoint-down(sm) {
                            width: 100%;
                            padding-inline: 14px;
                        }
                        span{
                            text-transform: uppercase;
                        }
                    }


                    .bat-form---back {
                        text-align: left;

                        button {
                            background-image: url("/content/dam/glo-it/images/Glo-Chevron-left.png");
                            background-repeat: no-repeat;
                            height: 46px!important;
                        }

                        .bat-button--secondary--dark {
                            border: none;

                            &:hover,
                            &:focus {
                                outline: none;
                            }
                        }
                    }

                    &.first-section {
                        button {
                            @include media-breakpoint-down(sm) {
                                width: 45%;
                            }
                        }
                    }
                }

                &-more {
                    .bat-form---more {
                        textarea {
                            resize: none;
                        }
                    }

                    &.hidden {
                        display: none;
                    }
                }

                &-checkbox-1,
                &-checkbox-2 {
                    padding: 20px;
                    border: 1px solid #DEDEDE;
                }

                &-checkbox-1 {
                    margin-bottom: 25px;

                    .bat-form---policy-checkbox,
                    .bat-form---profiling-checkbox,
                    .bat-form---newsletter-checkbox,
                    .bat-form---contact-checkbox {
                        margin-bottom: 0px;

                        fieldset {
                            margin-bottom: 0px;

                            label {
                                margin-bottom: 0px;

                                input {
                                    &::before {
                                        color: #C4C4C4;
                                    }
                                }

                                span {
                                    font-size: 12px;
                                    padding-bottom: 0px;
                                    color: $brand-black;
                                }
                            }
                        }
                    }

                    .bat-form---newsletter-checkbox {
                        fieldset {
                            label {
                                input {
                                    display: none;
                                }

                                span {
                                    margin-bottom: 15px;
                                }
                            }
                        }
                    }

                    .bat-form---contact-checkbox {
                        fieldset {
                            display: flex;

                            label {
                                margin-right: 20px;
                            }
                        }
                    }

                    .bat-form---policy-checkbox-1,
                    .bat-form---contact-checkbox {
                        fieldset {
                            label {
                                span {
                                    margin-bottom: 15px;
                                }
                            }
                        }
                    }
                }

                &-checkbox-2 {

                    .bat-form---policy-checkbox-1,
                    .bat-form---policy-checkbox-2 {
                        margin-bottom: 0px;

                        fieldset {
                            margin-bottom: 0px;

                            label {
                                margin-bottom: 0px;

                                input {
                                    &::before {
                                        color: #C4C4C4;
                                    }
                                }

                                span {
                                    font-size: 12px;
                                    padding-bottom: 0px;
                                    color: $brand-black;
                                }
                            }
                        }
                    }

                    .bat-form---policy-checkbox-1 {
                        fieldset {
                            label {
                                span {
                                    margin-bottom: 15px;
                                }
                            }
                        }
                    }
                }
            }

            &.submit {

                .bat-form--signup-back,
                .bat-form--signup-submit {
                    width: fit-content;

                    button {
                        width: 220px;
                    }

                    @include media-breakpoint-down(sm) {
                        width: 47%;

                        button {
                            width: 100%;
                            padding-inline: 0px;
                        }
                    }
                }

                .bat-form--signup-submit {
                    text-align: right;
                }

                .bat-form--signup-back {
                    button {
                        background-image: url("/content/dam/glo-it/images/Glo-Chevron-left.png");
                        background-repeat: no-repeat;
                        height: 100%;
                    }

                    .bat-button--secondary--dark {
                        border: none;

                        &:hover,
                        &:focus {
                            outline: none;
                        }
                    }
                }

                &.checkoutSubmit {
                    button {
                        width: 240px;

                        @include media-breakpoint-down(sm) {
                            width: 100%;
                            padding-inline: 0px;
                        }
                    }
                }
            }

            .bat-form--signup-messages {
                .error-msg {
                    font-size: 15px;
                    text-align: center;
                }
            }
        }
    }

    &.mobile-app {
        .bat-signup-gloit-tabs {
            .tab {
                width: 32px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100%;
                color: $black;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                background-color: unset;
                border-bottom: unset;
                padding: 0;

                &.active {
                    background: linear-gradient(90deg, #E97129 0%, #C74206 100%);
                    color: $white;
                    font-weight: 700;
                }
            }

            .tab-separator {
                border: 2px solid #DEDEDE;
                height: 0;
                width: 40px;
            }

        }

        .tab-title {
            display: none;
            margin: 20px 0;
            padding: 0 20px;

            &.active {
                display: block;
            }
        }

        .bat-form-container-continue.first-section {
            .bat-button {
                width: 100%;
                max-width: 100%;
            }
        }
    }


    //select2 styles
    .select-multiple-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin: 0px !important;

        .select2 {
            width: 100% !important;

            .select2-selection {
                background: #efefef;
                border-radius: 0px;
                border: none;

                .select2-selection__choice__display {
                    font-size: 14px;
                }
            }
        }

        &:after {
            color: #2a2a2c;
            content: "\e920";
            font-family: icomoon;
            font-size: 24px;
            height: 22px;
            pointer-events: none;
            position: relative;
            right: 30px;
            bottom: -4px;
            width: 0px;
            font-weight: 600;
        }
    }
    .bat-new-signup-gloit-form .bat-form-block .bat-form-container-nation{display:none!important;}
}

.select2-dropdown {
    border: none;

    .select2-results__options {

        border-radius: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
        max-height: 250px;

        .select2-results__option {
            font-size: 14px;
            line-height: 15px;
            background-color: #efefef;
            border: 0;
            color: #1d1d1b;
            font-family: Mont, sans-serif;
            margin: 0px;
            padding-left: 20px;
            padding-right: 20px;

            &.select2-results__option--highlighted.select2-results__option--selectable {
                background-color: #5897fb !important;
                color: white !important;
            }
        }


    }
}
 

.newsignup-image-steps{
    .image{
        margin: auto !important;
        width: 60% !important;
        padding-bottom: 33px;
        @media screen and (max-width: 768px){
            width: 95% !important;
            padding-bottom: 0px;
        }
    }
    &.no-padding { 
        .image{
            padding-bottom: 0;
        }
    }
}

.bat-form--signup-messages {
    p {
        &.success-message{ 
            font-size: 14px; 
            background: #c5eeac;
            color: #1d1d1b;
            padding: 10px;
            text-align: center;
        }
    }
}

.bat-salesforceregistration-gloit {
    .bat-form-block {
        &.submit {
            display: block;
            margin-top: 10px;
        }
    }
}