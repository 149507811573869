bat-hero-glors {
	.bat-hero {
		height: 100%;
		img {
			position: relative;
			left: 0;
			top: 0;
			transform: unset;
		}
	}
	&.masthead {
		&-center {
			.bat-hero-content {
				position: absolute;
				top: auto;
				bottom: 15%;
				width: 100%;
				display: grid;
				justify-content: center;
			}
			.bat-hero-content-container {
					width: fit-content;
			}
		}
		&-left {
			.bat-hero-content {
				position: absolute;
				padding-left: 6%;
				bottom: 30%;
				@include media-breakpoint-down(sm) {
					bottom: 0;
    				top: 0;
				}
			}
			.bat-hero-content-container {
				width: fit-content;
			}
		}
		&-right {
			.bat-hero-content {
				position: absolute;
				bottom: 50%;
				width: 100%;
				padding-right: 5%;
				display: grid;
				justify-content: flex-end;
				@include media-breakpoint-down(sm) {
					bottom: 5%;
				}
			}
			.bat-hero-content-container {
					width: fit-content;
			}
		}
	}
	.disclaimer {
		color: $white;
    	margin-top: 20px;
		font-size: 12px;
	}
}
