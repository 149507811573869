.bat-messagebar {
	//-----------------------------------------------------------------| Default
	&--default {
		display: block;
		margin: 0;
		max-height: 0;
		transition: max-height 0.3s ease-in;

		&.active {
			display: block;
			margin-bottom: 0;
			margin-top: 0;
			max-height: unset;
		}

		&-message {
			&-error {
				background-color: $tint-error;
				p {
					color: $red;
				}
			}
			background-color: $cart-banner;
			color: $white;
			font-weight: 700;
			opacity: 0;
			padding: 10px 20px;
			transition: opacity 0.3s ease-in 0.3s;

			.active & {
				opacity: 1;
			}

			p {
				display: inline;
				line-height: 1rem;
			}
		}
	}
}

.bat-messagebar-error-simplified {
	position: fixed;
	top: 159px;
	width: 100%;
    left: 0;
    z-index: 999;

	@include media-breakpoint-up(sm) {
		top: 205px;
	}

	&--default {
		display: block;
		margin: 0;
		max-height: 0;
		transition: max-height 0.3s ease-in;
		overflow: hidden;
		text-align: center;

		&.active {
			display: block;
			margin-bottom: 0;
			margin-top: 0;
			max-height: unset;
		}

		&-message-error {
			background-color: $tint-error;
			color: $white;
			font-weight: 700;
			opacity: 0;
			padding: 10px 20px;
			transition: opacity 0.3s ease-in 0.3s;
			height: 52px;

			@include media-breakpoint-up(sm) {
				height: 60px;
			}

			.active & {
				opacity: 1;
			}

			p {
				font-weight: 700;
				font-size: 10px;
				line-height: 13px;
				color: $red;
				margin: 0;
				
				@include media-breakpoint-up(sm) {
					font-size: 14px;
					line-height: 18px;
				}
			}
		}
	}
}
