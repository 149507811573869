bat-cta-gloitsticky {
	max-width: unset !important;
}

.bat-sticky-cta-gloit{
	width: 100%;
	background: #F8F8F8;
	box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.1);
	padding: 21px 30px;
	display: flex;
    justify-content: center;
    align-items: center;
	position: fixed;
    bottom: 0;
	z-index: 999;

	.bat-cta-style {
		margin: 0;
	}

	&-placeholder {
		height: 87px;
		width: 100%;
	}
}