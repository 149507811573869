.bat-confirmemail-glors {
    .bat-confirmemail-glors-form {
        font-size: 14px;
        padding: 10px;
        text-align: center;
        color: $blackish;
        .success-message {
            padding: 10px;
            background: $pista-green;
        }
        .error-message {
            padding: 10px;
            background: $light-red;
        }
    }
}