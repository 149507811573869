.bat-capverify-gloit{
    background-color: #efefef;
    .bat-capverify-gloit-form{
        margin: 10% 8% 10% 8%;
        @include media-breakpoint-down(sm){
            margin: 5%;
        }

        .bat-form--cap-verify-submit button {
            position: relative;
            &.loading.disabled{
                background: linear-gradient(267.63deg,#ba4304 .65%,#d7722d 100%);
                opacity: 0.5;
            }
        }


        .bat-form--cap-verify-submit button.loading:after {
            content: "";
            position: absolute;
            width: 30px;
            height: 30px;
            border: 8px solid lightgray;
            border-radius: 50%;
            border-top: 8px solid #2f539f;
            animation: spin 1s linear infinite;
            z-index: 10;
        }

        #step1{
            #step1Form{
                display: flex;
                align-content: center;
                justify-content: flex-start;
                align-items: center;
                gap: 5%;

                input{
                    background-color:white;
                    border: solid 1px #6B6B6B !important;
                }
                .bat-form-block {
                    margin:0;
                    min-width: 350px;
                    @include media-breakpoint-down(sm){
                        min-width: 0px;
                    }
                }
            }
            .bat-form-heading{
                padding-top: 5%;
                width: 60%;
                padding-bottom: 4%;

                @include media-breakpoint-down(sm){
                    width: 80%;
                }
                h1{
                    color:#2A2C2C;
                    font-family: Mont;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 900;
                    line-height: 115%;
                    letter-spacing: 0.5px;
                }
            }
            
        }
        .bat-capverify-gloit-form-info-success-container{
            display: flex;
            align-items: center;
            gap: 32px;
        }
        &-info, &-info-glovo, &-info-sameday{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5%;
            width: 35%;
            padding-bottom: 3%;
            @include media-breakpoint-down(sm){
                width: 60%;
            }
            p{
                color: #2A2C2C;
                font-family: Mont;
                font-size: 11px;
                font-style: normal;
                font-weight: 800;
                line-height: 110%;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                width: 55%;
            }

            img{
                width: 15%;
            }
            &-success{
                display: flex;
                align-items: center;
                @include media-breakpoint-down(sm){
                    gap: 3%;
                    img{
                        width: 8%;
                    }
                    p{
                        width:75%;
                    }
                }
                p{
                    color: #0DB14E;
                    font-size: 10px;
                    font-weight: 700;
                }
                img{
                    width: 6%;
                }
            }
            &-error{
                display: flex;
                align-items: center;
                @include media-breakpoint-down(sm){
                    gap: 3%;
                    img{
                        width: 8%;
                    }
                    p{
                        width:75%;
                    }
                }
                p{
                    color: #2F539F;
                    font-size: 10px;
                    font-weight: 700;
                }
                img{
                    width: 6%;
                }
            }
        }
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}