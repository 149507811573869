bat-mapstorelocator-glors{
    width: 100%;
}

.bat-mapstorelocator-gloit{
    #map{
        width: 100%;
        height: 965px;

        @include media-breakpoint-down(md){
            height: 380px;
        }

        #content{
            .title{
                margin: 0px 0px 10px;
                @include font-rem(18px, 20px);

                @include media-breakpoint-down(md){
                    @include font-rem(16px, 20px);
                }        
            }

            .address{
                @include font-rem(14px, 20px);
                color: #6B6B6B;

                @include media-breakpoint-down(md){
                    @include font-rem(13px, 20px);
                }
            }

            .direction {
                -webkit-appearance: button;
                -moz-appearance: button;
                appearance: button;
                border: 1px solid #979797;
                border-radius: 30px;
                padding: 8px 15px;
                font-style: normal;
                font-weight: 700;
                font-size: 10px;
                font-family: "Mont";
                line-height: 10px;
                text-align: center;
                letter-spacing: .05em;
                text-transform: uppercase;
                color: #979797;
                background: rgba(255,255,255,.2);
            }
        }

        .gm-style-iw-t{
            &::after{
                display: none;
            }
        }

        .gm-style-iw-c{
            border-radius: 0px;
            border: 2px solid #DEDEDE;
        }
    }
}