bat-card-gloitimagegrid {
    max-width: 100% !important;
    width: 100%;
}

.imagegrid-card {
    width: 100%;
    margin-top: 50px;

    &-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 40px 64px;
        gap: 24px;
        background: linear-gradient(45deg, #5482c2 0, #353174 100%) !important;

        @include media-breakpoint-down(md) {
            padding: 20px 40px 500px 40px;
        }

        &-image {
            margin-top: -45px;
            max-width: 135px;
        }

        &-content {
            text-align: center;

            &-title {
                font-weight: 700;
                font-size: 26px;
                line-height: 30px;
                color: $white;
                margin-bottom: 16px;

                @include media-breakpoint-down(md) {
                    font-size: 20px;
                    line-height: 24px;
                }
            }

            &-text {
                p {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 28px;
                    color: $white;
                    
                    @include media-breakpoint-down(md) {
                        font-size: 18px;
                        line-height: 26px;
                    }
                }
            }
        }
    }

    &-grid {
        margin-top: -40px;
        margin-left: auto;
        margin-right: auto;
        max-width: 1400px;
        padding-right: 30px;

        @include media-breakpoint-down(md) {
            margin-top: -470px;
        }

        &-image {
            filter: drop-shadow(8px 8px 0px #D5DDEC);
        }
    }
}