bat-carousel-glors {
     .bat-carousel {
        max-width: none;
        .slick-prev {
            display: inline-block !important;
            top: auto !important;
            bottom: 5px;
            left: 40% !important;
            @include media-breakpoint-down(md) {
                left: 15% !important;
                bottom: 18px;
            }
            .bat-icon {
                color: $white;
                &.icon-arrow-left:before {
                    font-family: "FontAwesome" !important;
                    content: "\f104";
                }
            }
        }
        .slick-next {
            display: inline-block !important;
            top: auto !important;
            bottom: 2px;
            right: 40% !important;
            @include media-breakpoint-down(md) {
                right: 15% !important;
                bottom: 15px;
            }
            .bat-icon {
                color: $white;
                &.icon-arrow-right:before {
                    font-family: "FontAwesome" !important;
                    content: "\f105";
                }
            }
        }
        .slick-dots {
            bottom: 20px;
            justify-content: center;
            margin: 0 auto;
            li {				
                button {
                    background-color: $light-gray !important;
                    border: 1px solid $light-gray !important;
                    height: 12px;
                    width: 12px;
                }
    
                &.slick-active {
                    button {
                        background-color: $pantone-orange !important;
                        border: 1px solid $pantone-orange !important;
                        width: 12px;
                        height: 12px;
                    }
                }
            }
        }
     }
}