//BUTTONS
%button-base {
	@include font-rem(13px, 16px);
	border-radius: 100px;
	font-family: $primary-font;
	font-weight: 700;
	letter-spacing: 0.048em;
	max-width: 300px;
	overflow: hidden;
	padding: 13px 38px;
	position: relative;
	text-align: center;
	text-decoration: none;

	&.icon-left {
		padding: 13px 38px 13px 28px;

		i {
			margin-right: 5px;
		}
	}

	&.icon-right {
		padding: 13px 28px 13px 38px;

		i {
			margin-left: 5px;
		}
	}

	i,
	span {
		position: relative;
		z-index: 1;
	}

	span {
		display: inline-block;
		vertical-align: top;
	}

	&:hover {
		text-decoration: none;

		span {
			text-decoration: none;
		}
	}

	i {
		height: 18px;
		width: 18px;

		&:before {
			font-size: 18px;
		}
	}
}

.bat-button {
	@extend %button-base;

	//-----------------------------------------------------------------| Button Dark (Default Color Dark)
	&--dark {
		background: $cta-primary-gradient;
		border: solid 2px transparent;
		background-origin: border-box;
		color: $white;
		transition: background 0.5s ease;

		i {
			svg {
				fill: $white;
			}
		}

		&:hover {
			background: $cta-primary-gradient-hover;
			background-origin: border-box;

			span {
				color: $white;
			}

			i {
				svg {
					fill: $white;
				}
			}
		}

		&.disabled,
		&:disabled {
			background: $concrete;
			border: solid 2px $concrete;
			color: $dark-gray;
		}
	}

	//-----------------------------------------------------------------| Button Light
	&--light {
		background: $cta-primary-gradient;
		border: solid 2px transparent;
		background-origin: border-box;
		color: $white;
		transition: background 0.5s ease;

		i {
			svg {
				fill: $white;
			}
		}

		&:hover {
			background: $cta-primary-gradient-hover;
			background-origin: border-box;

			span {
				color: $white;
			}

			i {
				svg {
					fill: $white;
				}
			}
		}

		&.disabled,
		&:disabled {
			background: $light-gray;
			color: $dark-gray;
		}
	}

	//-----------------------------------------------------------------| Button White
	&--white {
		background: $white;
		border: solid 2px transparent;
		background-origin: border-box;
		color: $nero-black;
		transition: background 0.5s ease;

		i {
			svg {
				fill: $nero-black;
			}
		}

		&:hover {
			background: $white;
			background-origin: border-box;

			span {
				color: $nero-black;
			}

			i {
				svg {
					fill: $nero-black;
				}
			}
		}

		&.disabled,
		&:disabled {
			background: $concrete;
			border: solid 2px $concrete;
			color: $dark-gray;
		}
	}

	//-----------------------------------------------------------------| Button Facebook
	&--facebook {
		background: #1877f2;
		border: solid 2px transparent;
		background-origin: border-box;
		color: $white;
		transition: background 0.5s ease;

		i {
			svg {
				fill: $white;
			}
		}

		&:hover {
			background: #125dbf;
			background-origin: border-box;

			span {
				color: $white;
			}

			i {
				svg {
					fill: $white;
				}
			}
		}

		&.disabled,
		&:disabled {
			background: $light-gray;
			color: $dark-gray;
		}
	}

	//-----------------------------------------------------------------| Button Google
	&--google {
		background: #ffffff;
		border: solid 1px grey;
		background-origin: border-box;
		color: $brand-black;
		transition: background 0.5s ease;

		i {
			svg {
				fill: $white;
			}
		}

		&:hover {
			background: #e5e5e5;
			background-origin: border-box;

			span {
				color: $brand-black;
			}

			i {
				svg {
					fill: $white;
				}
			}
		}

		&.disabled,
		&:disabled {
			background: $light-gray;
			color: $dark-gray;
		}
	}

	//-----------------------------------------------------------------| Button Dark Secondary
	&--secondary--dark {
		background: transparent;
		border: solid 2px $orange-cta-gradient-end;
		color: $brand-black;
		transition: border 0.5s ease, color 0.5s ease;

		i {
			svg {
				fill: $orange-cta-gradient-end;
			}
		}

		&:hover {
			background: transparent;
			border: solid 2px $orange-cta-gradient-hover-start;
			color: $white;

			span {
				color: $white;
			}

			i {
				svg {
					fill: $white;
				}
			}
		}

		&.disabled,
		&:disabled {
			border: solid 2px $gray;
			color: $dark-gray;
		}
	}

	//-----------------------------------------------------------------| Button Light Secondary
	&--secondary--light {
		background: transparent;
		border: solid 2px $orange-cta-gradient-end;
		color: $white;
		transition: border 0.5s ease, color 0.5s ease;

		i {
			svg {
				fill: $white;
			}
		}

		&:hover {
			background: transparent;
			border: solid 2px $orange-cta-gradient-hover-start;
			color: $white;

			span {
				color: $white;
			}

			i {
				svg {
					fill: $white;
				}
			}
		}

		&.disabled,
		&:disabled {
			background: $dark-gray;
			border-color: transparent;
			color: $white;
		}
	}

	//-----------------------------------------------------------------| Button White Secondary
	&--secondary--white {
		background: transparent;
		border: solid 2px $white;
		color: $white;
		transition: border 0.5s ease, color 0.5s ease;

		i {
			svg {
				fill: $white;
			}
		}

		&:hover {
			background: transparent;
			border: solid 2px $white;
			color: $white;

			span {
				color: $white;
			}

			i {
				svg {
					fill: $white;
				}
			}
		}

		&.disabled,
		&:disabled {
			background: $dark-gray;
			border-color: transparent;
			color: $white;
		}
	}

	//-----------------------------------------------------------------| Button Disabled
	&.disabled,
	&:disabled {
		opacity: 1;
	}
}
