.bat-sticky-card-gloit{
	max-width: 1400px;
	width: 100%;
	top: auto !important;
	bottom: 37px;
	z-index: 900;

	&.uk-active {
		.bat-sticky-card-gloit--blurb {
			display: flex;
		}
	}

	@media (max-width: 366px) {
		bottom: 41px;
	}

    &--blurb {
		display: none;
    	margin-left: auto;
		margin-right: 80px;
		padding: 0 10px;
		background: url(/content/dam/glo-it/images/masthead-card/masthead-card3.svg) center bottom repeat-x;
		justify-content: space-between;
		color: #fff;
		width: 32%;
		align-items: flex-end;

		@include media-breakpoint-down(md) {
			width: 77%;
			margin: 0;
			flex-direction: row-reverse;
		}

		@media (max-width: 366px) {
			width: 85%;
		}

		&-text {
			margin-bottom: 10px;
			margin-left: 10px;
			margin-right: 10px;
			min-width: fit-content;

			@include media-breakpoint-down(md) {
				margin-bottom: 5px;
			}

			.bat-card--blurb-title {
				color: $white;
				@include font-rem(10px, 1em);
                font-weight: 500;
				margin-top: 5px;
				margin-bottom: 5px;
			}

			.bat-card--blurb-andmore{
				@include font-rem(20px, 1em);
				font-weight: 500;
				p {
					@include font-rem(20px, 1em);
    				font-weight: 500;

					@include media-breakpoint-down(md) {
						@include font-rem(15px, 1em);
					}
				}
				
				p:last-child {
					margin-bottom: 0;
				}
			}

			.card-price{
				@include font-rem(41px, 1em);
				font-weight: 500;
				color: $white;
				align-items: flex-start;
				width: 100%;
				justify-content: flex-end;

				@include media-breakpoint-down(md) {
					@include font-rem(20px, 1em);
				}
			}
		}

		&-img {
			margin-top: -40px;
			width: 80px;
			height: 145px;

			@include media-breakpoint-down(md) {
				margin-top: -60px;
				width: 70px;
    			height: 130px;
			}

			> a {
				display: block;
			}

			img {
				object-fit: cover;
				object-position: top;
			}
			@include media-breakpoint-down(sm) {
				img {
					object-fit: cover;
					object-position: top;
				}
			}
		}

		&-ctalist {
			align-self: center;

			.button-light {
				@include media-breakpoint-down(md) {
					padding: 10px;
					font-size: 11px;
				}
			}

			.button-secondary-light {
				border-color: $white;
				color: $white;
				padding-top: 7px;
				padding-bottom: 7px;
				padding-left: 20px;
				padding-right: 20px;
			}
			.button-secondary-dark {
				border-color: $black;
				color: $black;
				padding-top: 7px;
				padding-bottom: 7px;
				padding-left: 20px;
				padding-right: 20px;
			}
			a:hover{
				span{
					color: $black;
				}
				
			}
		}
	}

	.hyper-pro & {
		.bat-sticky-card-gloit--blurb {
			background: url(/content/dam/glo-it/animations/discoverglo/popup-bg.png) center bottom repeat-x;
		}

		.bat-cta-style {
			box-shadow: 5px 5px 10px 2px #333333;
			-webkit-box-shadow: 5px 5px 10px 2px #333333;
			-moz-box-shadow: 5px 5px 10px 2px #333333;
		}
	}
}