.aem-GridColumn{
	&.news-card{
		padding: 70px 50px;
        background-color: $concrete;

		@include media-breakpoint-down(sm){
			padding: 0px;
		}
    }

    .title-section{
        @include media-breakpoint-down(sm){
            display: block;
			text-align: center;
            padding-top: 30px;
		}
        
    }

    @include media-breakpoint-down(xl){
        .aem-Grid.aem-Grid--12 > .aem-GridColumn.aem-GridColumn--tablet--6 {
            width: 100%;
        }
    }

    .news-section-title{
        @include font-rem(22px, 28px);
        font-weight: 800;

        @include media-breakpoint-down(sm){
            margin-bottom: 10px;
		}
    }

    .news-section-link{
        text-decoration: none;
        color: $charcoal;
    
        @include font-rem(14px, 28px);
        font-weight: 800;

        i{
            margin-top: 2px;
            margin-left: 10px;
        }
    }
}
.bat-loyaltyinfo-gloit{

    &--news {
        display: flex;
        align-items: center;

        @include media-breakpoint-down(md) {
            flex-direction: column;
        }

		&-img {
            min-width: 100px;
            z-index: 1;

			a {
				display: block;
			}

            @include media-breakpoint-down(md) {
                align-self: flex-start;
            }
		}

        &-main {
            min-height: 130px;
            display: flex;
            align-items: center;
            background: linear-gradient(45deg, #5482c2 0, #353174 100%) !important;
            margin-left: -80px;
            width: 100%;

            @include media-breakpoint-down(md) {
                margin-left: 0;
                margin-top: -80px;
            }
        }

        &-title{
            @include font-rem(24px, 28px);
            font-weight: 800;
            color: $white;
        }

        &-content{
            p {
                @include font-rem(20px, 28px);
                font-weight: 400;
                color: $white;
            }
        }

		&-text {
			margin: 20px 20px 20px 90px;

			@include media-breakpoint-down(xs) {
				margin: 90px 20px 20px 20px;
			}

		}
		&-ctalist {
			.bat-cta-style {
				font-size: 15px;
				line-height: 150%;
				margin-top: 5px;
                text-transform: uppercase;
                @include font-rem(15px, 28px);
                font-weight: 800;
                color: $charcoal;

				@include media-breakpoint-down(sm) {
					margin-top: 0;
				}
				
			}
			
			.button-news{
				@include media-breakpoint-up(sm){
					&:hover {
						transform: translateX(50px);
					}
					transition: all .5s ease-out;
				}
				
			}

            a{
                text-decoration: none;
                color: $charcoal;
            
                @include font-rem(14px, 28px);
                font-weight: 800;
        
                i{
                    margin-top: 2px;
                    margin-left: 10px;
                }
            }
            a:hover{
                text-decoration: none;
                color: $charcoal;

                span{
                    text-decoration: none;
                    color: $charcoal;
                }
            }

            span{
                font-weight: 800;
            }

            @include media-breakpoint-down(sm) {
                padding-top: 10px;
            }
		}
	}

    .right-img & {

        &--news {
            flex-direction: row-reverse;
            justify-content: flex-end;

            @include media-breakpoint-down(md) {
                flex-direction: column-reverse;
                justify-content: unset;
            }

            &-img {
                @include media-breakpoint-down(md) {
                    align-self: flex-end;
                    margin-top: -25px;
                }
            }

            &-main {
                margin-left: 0;
                margin-right: -80px;

                @include media-breakpoint-down(md) {
                    margin-right: 0;
                    margin-bottom: -40px;
                    margin-top: 0;
                }
            }

            &-text {
                margin: 20px 90px 20px 20px;

                @include media-breakpoint-down(xs) {
                    margin: 20px 20px 60px 20px;
                }
            }
        }
    }
}